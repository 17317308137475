import i18n from '../../locales/i18n'

export enum EPaginationOptions {
    SIZE_10 = 10,
    DEFAULT = 25,
    SIZE_25 = 25,
    SIZE_50 = 50,
    SIZE_100 = 100,
    ALL = -1
}

export const ALL_VALUE = {
    value: EPaginationOptions.ALL,
    title: i18n.global.t('common.all')
}

export const EPaginationOptionsValues = [
    { value: EPaginationOptions.SIZE_10, title: EPaginationOptions.SIZE_10.toString() },
    { value: EPaginationOptions.SIZE_25, title: EPaginationOptions.SIZE_25.toString() },
    { value: EPaginationOptions.SIZE_50, title: EPaginationOptions.SIZE_50.toString() },
    { value: EPaginationOptions.SIZE_100, title: EPaginationOptions.SIZE_100.toString() }
]
