<script setup lang="ts">
import { AutocompleteField } from '@rfc/rfc-components-library'
import store from '@/store'
import { EFiltersActions } from '@/store/filtersStore/FiltersStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import { ref, watch } from 'vue'
import checkpointApi from '../../services/checkpoint.service'

type Props = EventCheckpointType & {
    inputValue?: string
    checkpoint?: Partial<CheckpointModel>
    loadingCheckpoints?: boolean
    keepCheckpointInStorage?: boolean
    disabled?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'typing', value?: string): void
    (e: 'change', value?: CheckpointModel): void
}
const emit = defineEmits<Emits>()

const getCheckpoints = (name?: string) => {
    if (!props.event?.id) return
    checkpointApi
        .getCheckpointsFromEvent(props.event.id, {
            per_page: -1,
            filters: name ? { name } : undefined
        })
        .then(res => (checkpointOptions.value = res.data ?? res))
}
const checkpointOptions = ref<CheckpointModel[]>([])

const handleSelect = (checkpoint?: CheckpointModel) => {
    if (props.keepCheckpointInStorage)
        store.value?.dispatch(
            `${EStoreModules.FILTERS}/${EFiltersActions.SET_FILTER}`,
            checkpoint ? { checkpoint: { name: checkpoint.name, id: checkpoint.id } } : undefined
        )
    emit('change', checkpoint)
}

watch(
    () => props.event?.id,
    newValue => (newValue ? getCheckpoints() : undefined),
    { immediate: true }
)
</script>

<template>
    <AutocompleteField
        :label="$t('device.filters.checkpoint')"
        :modelValue="checkpoint"
        :loading="loadingCheckpoints"
        @typing="$emit('typing', $event)"
        @select="handleSelect($event as CheckpointModel)"
        labelKey="name"
        valueKey="id"
        :options="checkpointOptions"
        :disabled="disabled" />
</template>
