<script setup lang="ts">
import type { FFDragEvent } from '@/types/Firefox/DragEvent'
import { type AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { BaseCard, TypographyItem } from '@rfc/rfc-components-library'
import { type Position } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    device: AssociatedDeviceModel
}
const props = defineProps<Props>()
type Emits = {
    (e: 'dragStart', device: AssociatedDeviceModel): void
    (e: 'dragEnd', position?: Position): void
}
const emit = defineEmits<Emits>()

const handle = ref<{ cardRef: HTMLDivElement } | null>(null)

const mouseDown = (event: any) => {
    event.stopPropagation()

    const hideDragImage = event.currentTarget?.cloneNode(true)
    hideDragImage.id = 'hidedraggeimage'
    const dragImage = event.currentTarget?.cloneNode(true)
    dragImage.id = 'draggeimage'

    dragImage.style.position = 'absolute'
    dragImage.style.visibility = 'hidden'
    dragImage.style.transform = 'translate(-50%, -50%)'
    hideDragImage.style.opacity = 0

    document.body.appendChild(hideDragImage)
    document.body.appendChild(dragImage)

    event.dataTransfer?.setDragImage(hideDragImage, event.x, event.y)
    event.dataTransfer.effectAllowed = 'move'

    emit('dragStart', props.device)
}
const mouseUp = (event: FFDragEvent) => {
    const dragImage = document.getElementById('draggeimage')
    const hideDragImage = document.getElementById('hidedraggeimage')
    if (dragImage && handle.value) {
        handle.value.cardRef.style.position = 'relative'
        handle.value.cardRef.style.removeProperty('visibility')
        handle.value.cardRef.style.removeProperty('left')
        handle.value.cardRef.style.removeProperty('top')
    }
    hideDragImage?.remove()
    dragImage?.remove()
    emit('dragEnd', { x: event._ffix_cx ?? event.x, y: event._ffix_cy ?? event.y })
}

const setPosition = (event: DragEvent) => {
    const dragImage = document.getElementById('draggeimage')

    if (dragImage && handle.value) {
        dragImage.style.removeProperty('visibility')
        dragImage.style.left = `${event.x}px`
        dragImage.style.top = `${event.y}px`
        dragImage.style.pointerEvents = 'none'
        handle.value.cardRef.style.visibility = 'hidden'
    }
}
</script>

<template>
    <BaseCard
        ref="handle"
        :draggable="true"
        class="primary-border device-card fit-content"
        @dragstart="mouseDown"
        @dragend="mouseUp"
        @drag="setPosition">
        <template #content>
            <TypographyItem v-if="device.device_id || device.id" :label="device.name" />
        </template>
    </BaseCard>
</template>

<style scoped lang="scss">
.device-card {
    z-index: 2;
}
</style>
