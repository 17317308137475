import { API_PREFIX } from '@/config/api'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import type { SortKeyType } from '@/types/enum/SortByEnum'
import type { AdvancedEventFilters } from '@/types/filters/EventFilters'
import type { FilterType } from '@/types/filters/FilterType'
import type { EventCreateModel } from '@/types/models/EventCreateModel'
import type { EventModel } from '@/types/models/EventModel'
import { buildQueryUrl } from '@/utils/queryUtils'
import { api } from '@rfc/rfc-components-library/helpers'
import type { AxiosResponse } from 'axios'

const API_EVENT_PREFIX = `${API_PREFIX}/events`

const eventApi = {
    getEvent: (id: number) => api.get(`${API_EVENT_PREFIX}/${id}`),
    getEvents: (
        params?: Partial<
            PaginationType &
                FilterType<Partial<EventModel> & AdvancedEventFilters> & { sort: SortKeyType }
        >,
        noQueryParam?: boolean
    ): Promise<PaginationDataType<EventModel[]>> =>
        api
            .get(`${API_EVENT_PREFIX}${buildQueryUrl(params, noQueryParam)}`)
            .then(response => response.data),
    createEvent: (eventData: EventCreateModel): Promise<AxiosResponse<EventModel>> =>
        api.post(API_EVENT_PREFIX, eventData),
    updateEvent: (id: number, eventData: any) => api.patch(`${API_EVENT_PREFIX}/${id}`, eventData),
    deleteEvent: (id: number) => api.delete(`${API_EVENT_PREFIX}/${id}`)
}

export default eventApi
