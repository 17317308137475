<script setup lang="ts">
import store from '@/store'
import {
    CreateButton,
    InformationBaseCard,
    StackLayout,
    StringInputField
} from '@rfc/rfc-components-library'
import { EAuthActions, EStoreModules, EUserActions } from '@rfc/rfc-components-library/store'
import type { UserModel } from '@rfc/rfc-components-library/types'
import { isEqual } from 'lodash'
import { computed } from 'vue'

type Props = {
    me?: UserModel
}
const props = defineProps<Props>()
const currentData = computed(() => ({ ...store.value?.state.authentication.me }))

const localMe = computed<Partial<UserModel> | undefined>({
    get: () => props.me,
    set: value => value
})
const updateMeValue = (value: Partial<UserModel>) =>
    (localMe.value = { ...localMe.value, ...value })

const save = () => {
    if (!isEqual(currentData.value, localMe.value))
        store.value
            ?.dispatch(`${EStoreModules.USER}/${EUserActions.UPDATE_USER}`, {
                data: localMe.value,
                userId: currentData.value.id
            })
            .then(() => {
                store.value?.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.ME}`)
            })
}
</script>

<template>
    <InformationBaseCard :label="$t('account.informationsTitle')" class="full-width">
        <StackLayout direction="column" :gap="16" class="full-width">
            <StringInputField
                :label="$t('account.model.name')"
                :modelValue="localMe?.name"
                @change="name => updateMeValue({ name })"
                class="full-width" />
            <StringInputField
                :label="$t('account.model.email')"
                :modelValue="localMe?.email"
                @change="email => updateMeValue({ email })"
                class="full-width" />
            <CreateButton
                :loading="!!store?.state.user.loading"
                :label="$t('common.save')"
                @click="save" />
        </StackLayout>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.profile-role-card {
    & .card-content {
        text-align: center;
        border: 1px solid rgba(var(--v-theme-primary));
    }
}
</style>
