<script setup lang="ts">
import { FORGOT_PASSWORD_ROUTE } from '@/router/routes'
import store from '@/store'
import { getCurrentThemeMode } from '@/utils/viewsUtils'
import { LoginView } from '@rfc/rfc-components-library'
import { EAuthActions, EStoreModules } from '@rfc/rfc-components-library/store'
import { type LoginType } from '@rfc/rfc-components-library/types'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { darkModeLogo, lightModeLogo } from '@/assets/assetsUtils'

const router = useRouter()

const loading = computed(() => store.value?.state.authentication?.loading)

const submitForm = (value: LoginType) =>
    store.value?.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGIN}`, value)

const goToResetPassword = () => router.push(FORGOT_PASSWORD_ROUTE)

const logo = computed(() => ({
    mode: getCurrentThemeMode.value,
    darkModeLogo: darkModeLogo,
    lightModeLogo: lightModeLogo
}))

const backgroundUrl = new URL('@/assets/background.jpg', import.meta.url).href
</script>

<template>
    <LoginView
        :logo="logo"
        :backgroundUrl="backgroundUrl"
        :loading="loading"
        @goToResetPassword="goToResetPassword"
        @submit="submitForm" />
</template>

<style scoped lang="scss">
.onboarding-link {
    place-content: center;
    padding-top: 1em;
    & :deep(a) {
        text-decoration: underline;
        color: rgba(var(--v-theme-primary));
        text-decoration-color: rgba(var(--v-theme-primary));
        text-underline-offset: 4px;
    }
}
</style>
