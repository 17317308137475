<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import { ADMIN_APIS_ROUTES } from '@/router/routing/adminRouting/adminRoutes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { EApiActions } from '@rfc/rfc-components-library/store'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const data = ref<Partial<ApiModel>>({ ...defaultApiModel })
const loading = computed(() => store.value?.state.organization.apis?.loading)
const submitted = ref(false)

const updateApi = (value: Partial<ApiModel>) => (data.value = value)

const create = () => {
    submitted.value = true
    if (!data.value.name) return
    store.value
        ?.dispatch(`${EStoreModules.API}/${EApiActions.CREATE_API}`, data.value)
        .then(() => router.push(ADMIN_APIS_ROUTES.baseUrl))
}
</script>

<template>
    <ApiDetailView
        :data="data"
        :buttonLabel="$t('api.buttons.createApi')"
        hasPermission
        isCreateMode
        :loading="!!store?.state.organization.apis.loading"
        @click="create"
        @update="updateApi" />
</template>
