<script setup lang="ts">
import { buildDetectionArray } from '@/socket/socketUtils'
import store from '@/store'
import { ECheckpointActions } from '@/store/checkpointStore/CheckpointStoreTypes'
import { EStoreModules } from '@/store/storeType'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { ECheckpointsPerm } from '@/types/permissions/CheckpointsPermissionsEnum'
import type { SocketCheckpointDetectionModel } from '@/types/socket/SocketDetectionModel'
import CheckpointDetailView from '@/views/CheckpointsView/CheckpointDetailView.vue'
import { PermissionWrapper, StackLayout } from '@rfc/rfc-components-library'
import { EDetectionActions } from '@rfc/rfc-components-library/store'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import CheckpointCardMode from './CheckpointsTable/CheckpointCardMode.vue'
import EventCreateCheckpoint from './CreateCheckpoint/EventCreateCheckpoint.vue'

type Props = {
    currentTabIndex: number
    partnerConfig?: PartnerModel
    detections: SocketCheckpointDetectionModel[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'refresh'): void
    (e: 'goTo', value?: CheckpointModel): void
    (e: 'checkpointUpdate', value?: SocketCheckpointDetectionModel[]): void
    (e: 'refreshDevices', associates: number[]): void
}
const emit = defineEmits<Emits>()

const route = useRoute()
const eventId = computed(() => parseInt(route.params.id.toString()))
const checkpoint = ref<CheckpointModel>()

const data = computed(() => store.value?.state.checkpoint)
const latestDetections = ref<SocketCheckpointDetectionModel[]>([])
watch(
    () => props.detections,
    newDetection => (latestDetections.value = newDetection)
)

const getCheckpoints = () =>
    store.value
        ?.dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.FETCH_CHECKPOINTS}`, {
            id: eventId.value,
            params: { per_page: -1 }
        })
        .then((res: CheckpointModel[]) => {
            latestDetections.value = res.map(item => ({
                checkpoint_id: item.id,
                detections: []
            }))
            if (checkpoint.value)
                checkpoint.value = res.find(item => item.id === checkpoint.value?.id)
            store.value
                ?.dispatch(
                    `${EStoreModules.DETECTION}/${EDetectionActions.GET_LATEST_DETECTIONS}`,
                    {
                        checkpoint_ids: res.map(item => item.id)
                    }
                )
                .then((resDetections: DetectionModel[]) =>
                    emit(
                        'checkpointUpdate',
                        buildDetectionArray(resDetections, latestDetections.value, 'checkpoint_id')
                    )
                )
        })

onMounted(() => {
    getCheckpoints()
})
const handleDeleteCheckpoint = (value: CheckpointModel) =>
    store.value
        ?.dispatch(`${EStoreModules.CHECKPOINT}/${ECheckpointActions.DELETE_CHECKPOINT}`, {
            eventId: eventId.value,
            checkpointId: value.id,
            name: value.name
        })
        .then(() => getCheckpoints())

const handleAssociateDevice = () => {
    getCheckpoints()
    emit(
        'refreshDevices',
        data.value?.data?.checkpoints.flatMap(
            item => item.associates?.flatMap(associatedDevice => associatedDevice.device_id) ?? []
        ) ?? []
    )
}
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <StackLayout direction="column" :gap="8" class="full-width flex-wrap">
            <PermissionWrapper :permission="ECheckpointsPerm.CHECKPOINTS_EDIT">
                <EventCreateCheckpoint
                    :eventId="eventId"
                    :partnerConfig="partnerConfig"
                    :loading="!!store?.state.checkpoint.loading"
                    @refresh="getCheckpoints" />
            </PermissionWrapper>
            <CheckpointCardMode
                :currentTabIndex="currentTabIndex"
                :data="data?.data?.checkpoints"
                :detections="latestDetections"
                :loading="data?.loading ?? false"
                :eventId="eventId"
                :editPermission="ECheckpointsPerm.CHECKPOINTS_EDIT"
                :deletePermission="ECheckpointsPerm.CHECKPOINTS_DELETE"
                @goTo="checkpoint = $event"
                @delete="handleDeleteCheckpoint"
                @refresh="getCheckpoints" />
        </StackLayout>
        <CheckpointDetailView
            v-if="checkpoint"
            :checkpoint="checkpoint"
            :partnerConfig="partnerConfig"
            @refresh="getCheckpoints"
            @refreshAssociate="handleAssociateDevice" />
    </StackLayout>
</template>
