<script setup lang="ts">
import { CopyTypography } from '@rfc/rfc-components-library'
import { generateRandomString } from '@/mockupData/mockupUtils'

const generatedLink = generateRandomString(35)
</script>

<template>
    <CopyTypography :value="generatedLink" />
</template>

<style scoped lang="scss">
.invite-link {
    max-width: 100%;
}
</style>
