<script setup lang="ts">
import ExportDetectionButton from '@/components/Buttons/ExportDetectionButton.vue'
import { ESortBy, ESortByDate, getSortByKey } from '@/types/enum/SortByEnum'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { EventModel } from '@/types/models/EventModel'
import { defaultPaginationDataType, type PaginationDataType } from '@/types/PaginationType'
import { getQuerySort } from '@/utils/queryUtils'
import { getPageSize } from '@/utils/routeUtils'
import { FullTableLayout, StackLayout } from '@rfc/rfc-components-library'
import { detectionService } from '@rfc/rfc-components-library/services'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import EventDetectionsColumns from './EventDetectionsColumns.vue'

type Props = {
    event?: Partial<EventModel>
}
defineProps<Props>()

const route = useRoute()
const eventId = ref(parseInt(route.params.id.toString()))

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.DATE_RECENT)
const handleChangeSortBy = (sortBy: ESortBy) => {
    sort.value = sortBy
    getDetections()
}

const handleChangePageSize = (value: number) => {
    currentEventDetections.value.per_page = value
    currentEventDetections.value.current_page = 1
    getDetections()
}
const handleChangePageNumber = (pageNumber: number) => {
    currentEventDetections.value.current_page = pageNumber
    getDetections()
}
const currentEventDetections = ref<PaginationDataType<DetectionModel[]>>({
    ...defaultPaginationDataType
})
const getDetections = () =>
    detectionService
        .getDetections({
            filters: { event_id: eventId.value },
            sort: getSortByKey(sort.value),
            per_page: getPageSize() ?? currentEventDetections.value.per_page,
            page: currentEventDetections.value.current_page
        })
        .then(res => (currentEventDetections.value = res))

const table = ref()
</script>

<template>
    <StackLayout direction="column" :gap="8">
        <FullTableLayout
            :sortBy="sort"
            @changeSortBy="handleChangeSortBy"
            :orderOptions="ESortByDate"
            isResponsiveSmallScreen
            :perPage="currentEventDetections.per_page"
            :currentPage="currentEventDetections?.current_page"
            :lastPage="currentEventDetections?.last_page"
            :total="currentEventDetections?.total"
            @change:pageSize="handleChangePageSize"
            @change:pageNumber="handleChangePageNumber">
            <template #export>
                <ExportDetectionButton
                    v-if="event"
                    :event="event"
                    :sort="sort"
                    hideEventSelector
                    :perPage="currentEventDetections.per_page"
                    :currentPage="currentEventDetections.current_page" />
            </template>
            <template #table>
                <EventDetectionsColumns :data="currentEventDetections?.data" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
