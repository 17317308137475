import store from '@/store'
import type { MenuItemType } from '@/types/MenuItem'
import { EAuthActions, EStoreModules, EUserActions } from '@rfc/rfc-components-library/store'
import { computed } from 'vue'
import type { RouteRecordName } from 'vue-router'
import { EVENTS_ROUTE, MY_ACCOUNT_ROUTE } from './routes'
import { MY_ACCOUNT_ROUTE_NAME } from './routing/accountRoutes'
import { ECheckpointRoutesNamesValues } from './routing/checkpointsRoutes'
import { EEventRoutesNamesValues } from './routing/eventsRoutes'

export const menuRoutesTop: MenuItemType[] = [
    {
        icon: 'calendar-days',
        label: 'menu.events',
        to: EVENTS_ROUTE,
        permissions: true /* !!store.value?.state.authentication.me.organization_id &&
            userHasPermission(EEventsPerm.EVENTS_VIEW) */,
        names: [...EEventRoutesNamesValues, ...ECheckpointRoutesNamesValues]
    }
]

export const menuAccountItems = computed(() => ({
    myAccount: {
        label: store.value?.state.authentication?.me?.name,
        to: MY_ACCOUNT_ROUTE,
        names: [MY_ACCOUNT_ROUTE_NAME] as RouteRecordName[],
        withoutTranslation: true
    },
    impersonate: {
        icon: 'user-slash',
        label: 'menu.deimpersonate',
        click: () => {
            store.value
                ?.dispatch(`${EStoreModules.USER}/${[EUserActions.DEIMPERSONATION_USER]}`)
                .then(
                    () =>
                        store.value?.dispatch(
                            `${EStoreModules.AUTHENTICATION}/${[EAuthActions.ME]}`
                        )
                )
        },
        names: []
    },
    disconnect: {
        icon: 'logout',
        label: 'menu.logout',
        click: () =>
            store.value?.dispatch(`${EStoreModules.AUTHENTICATION}/${[EAuthActions.LOGOUT]}`),
        names: []
    }
}))
