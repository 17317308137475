<script setup lang="ts">
import DeviceTablePage from '@/components/DeviceComponents/DevicesTablePage.vue'
import router from '@/router'
import { DEVICE_ROUTES } from '@/router/routes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { ESortBy, ESortByAlphabetical } from '@/types/enum/SortByEnum'
import { type AdvancedDeviceFilters, type LocalStorageFilter } from '@/types/filters/DeviceFilters'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { getQueryFilters, getQuerySort } from '@/utils/queryUtils'
import { useTableModel } from '@rfc/rfc-components-library/hooks'
import { EDeviceActions } from '@rfc/rfc-components-library/store'
import type { DeviceFiltersType, DeviceModel } from '@rfc/rfc-components-library/types'
import { onMounted, ref } from 'vue'
import DevicesFilters from './components/DevicesFilters.vue'
import DeviceDetailView from './DeviceDetailView.vue'

const filters = ref<DeviceFiltersType & AdvancedDeviceFilters>({
    event_id: getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event?.id,
    checkpoint_id: getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.checkpoint?.id,
    ...getQueryFilters()
})

const deviceModel = useTableModel<DeviceModel, DeviceFiltersType, AdvancedDeviceFilters>(
    store.value,
    EStoreModules.DEVICE,
    EDeviceActions.FETCH_DEVICES,
    router,
    filters.value
)

onMounted(() => deviceModel.getData())

const advancedFilters = ref<AdvancedDeviceFilters>({})

const sort = ref<ESortBy>(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)
</script>

<template>
    <DeviceTablePage
        :data="{ ...deviceModel.data.value, data: deviceModel.data.value.data ?? [] }"
        :filters="deviceModel.filters.value ?? {}"
        :advancedFilters="advancedFilters"
        :orderOptions="ESortByAlphabetical"
        :sortBy="deviceModel.sort.value"
        :loading="deviceModel.loading.value"
        showNextEvent
        showNextCheckpoint
        :editPermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW"
        :deletePermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_DELETE"
        :editButtonHref="DEVICE_ROUTES.baseUrl"
        hrefKey="device_id"
        detailKey="device_id"
        @changePageSize="deviceModel.handleChangePageSize"
        @changePageNumber="deviceModel.handleChangePageNumber"
        @changeFilters="deviceModel.handleChangeFilters"
        @changeAdvancedFilters="deviceModel.handleChangeAdvancedFilters"
        @removeTag="deviceModel.handleRemoveTag"
        @changeSortBy="deviceModel.handleChangeSortBy"
        @search="deviceModel.handleSearch">
        <template #filters>
            <DevicesFilters :filters="filters" @change="deviceModel.handleChangeFilters" />
        </template>
        <template #outlookMode="props">
            <DeviceDetailView :device="props.device" isOutlookMode class="full-width" />
        </template>
    </DeviceTablePage>
</template>
