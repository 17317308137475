<script setup lang="ts">
import EventAutocomplete from '@/components/Filters/EventAutocomplete.vue'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import type { LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { AdvancedEventFilters, EventFilters } from '@/types/filters/EventFilters'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { ref } from 'vue'
import CheckpointAutocomplete from './CheckpointAutocomplete.vue'

type Props = EventCheckpointType & {
    keepEventInStorage?: boolean
    hideEventSelector?: boolean
    eventFilters?: Partial<EventFilters & AdvancedEventFilters>
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value: EventCheckpointType): void
}
const emit = defineEmits<Emits>()

const storedEvent = ref(
    props.event ?? getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event
)
const storedCheckpoint = ref(
    props.checkpoint ?? getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.checkpoint
)

const handleChange = (value: EventCheckpointType) => {
    if (value.checkpoint) {
        emit('change', { event: storedEvent.value, checkpoint: value.checkpoint })
        return (storedCheckpoint.value = value.checkpoint)
    }
    emit('change', { event: value.event, checkpoint: undefined })
    storedEvent.value = value.event
    storedCheckpoint.value = undefined
}
</script>

<template>
    <EventAutocomplete
        v-if="!hideEventSelector"
        :event="storedEvent"
        :filters="eventFilters"
        :keepEventInStorage="keepEventInStorage"
        @change="handleChange({ event: $event })"
        class="full-width" />
    <CheckpointAutocomplete
        :disabled="!storedEvent?.id"
        :event="storedEvent"
        :checkpoint="storedCheckpoint"
        :keepCheckpointInStorage="keepEventInStorage"
        @change="handleChange({ checkpoint: $event })"
        class="full-width" />
</template>
