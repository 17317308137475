<script setup lang="ts">
import { AutocompleteField } from '@rfc/rfc-components-library'
import eventApi from '@/services/event.service'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import { ESortBy, getSortByKey } from '@/types/enum/SortByEnum'
import type { LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { AdvancedEventFilters } from '@/types/filters/EventFilters'
import type { EventModel } from '@/types/models/EventModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { useDebounceFn } from '@vueuse/core'
import { nextTick, onMounted, ref } from 'vue'
import store from '../../store'
import { EFiltersActions } from '../../store/filtersStore/FiltersStoreTypes'
import { EStoreModules } from '../../store/storeType'

type Props = {
    event?: Partial<EventModel>
    keepEventInStorage?: boolean
    filters?: AdvancedEventFilters
}
const props = defineProps<Props>()
type Emits = {
    (e: 'change', value?: EventModel): void
}
const emit = defineEmits<Emits>()

const input = ref()
const handleChange = async (value: string) => {
    await nextTick(() => {
        if (value === props.event?.name) return
        loadingEvents.value = true
        input.value = value
        debouncedQuestion(value)
    })
}
const loadingEvents = ref(false)
const debouncedQuestion = useDebounceFn((value: string) => getEvents(value), EDebounceTime.DEFAULT)
const getEvents = (name?: string) => {
    eventApi
        .getEvents(
            {
                filters: {
                    name: name ? name : undefined,
                    ...props.filters
                },
                sort: getSortByKey(ESortBy.DATE_RECENT)
            },
            true
        )
        .then(res => {
            const currentEvent = getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)
                ?.event
            eventOptions.value =
                currentEvent && currentEvent.id
                    ? [
                          { ...currentEvent, id: currentEvent.id },
                          ...res.data.filter(item => item.id !== currentEvent?.id)
                      ]
                    : res.data
            if (props.event && !eventOptions.value.find(item => item.id === props.event?.id))
                eventOptions.value = [{ ...props.event, id: props.event.id }, ...eventOptions.value]
        })
        .finally(() => (loadingEvents.value = false))
}

const eventOptions = ref<Partial<EventModel>[]>([])

const handleSelect = (event?: EventModel) => {
    if (props.keepEventInStorage)
        store.value?.dispatch(
            `${EStoreModules.FILTERS}/${EFiltersActions.SET_FILTER}`,
            event ? { event: { name: event.name, id: event.id }, checkpoint: undefined } : undefined
        )
    emit('change', event)
}
onMounted(() => getEvents())
</script>

<template>
    <AutocompleteField
        :label="$t('device.filters.event')"
        :placeholder="$t('common.typeYourSearch')"
        :modelValue="event"
        @typing="handleChange"
        @select="handleSelect($event as EventModel)"
        labelKey="name"
        valueKey="id"
        :options="eventOptions" />
</template>
