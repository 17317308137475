<script setup lang="ts">
import { moment } from '@/locales/langUtils'
import type { EventFilters } from '@/types/filters/EventFilters'
import { EDebounceTime } from '@/utils/debounceUtils'
import { DatePickerField, StackLayout, StringInputField } from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters: EventFilters
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<EventFilters>): void
}
const emit = defineEmits<Emits>()

const handleChange = (value?: string) => {
    loadingEvents.value = true
    debouncedQuestion(value)
}
const loadingEvents = ref(false)
const debouncedQuestion = useDebounceFn(
    (name?: string) => emit('change', { name }),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout :gap="8" class="full-width" isResponsive>
        <StringInputField
            :label="$t('event.filters.eventName')"
            :modelValue="filters.name"
            @change="handleChange"
            class="full-width" />
        <StackLayout :gap="8" class="full-width" isResponsiveSmallScreen>
            <DatePickerField
                :label="$t('event.filters.startDate')"
                :modelValue="filters.start_at ? moment(filters.start_at).toDate() : undefined"
                @change="
                    $emit('change', {
                        start_at: $event ? moment($event).startOf('d').toDate() : $event
                    })
                "
                clearable
                class="full-width" />
            <DatePickerField
                :label="$t('event.filters.endDate')"
                :modelValue="filters.end_at ? moment(filters.end_at).toDate() : undefined"
                @change="
                    $emit('change', {
                        end_at: $event ? moment($event).endOf('d').toDate() : $event
                    })
                "
                clearable
                class="full-width" />
        </StackLayout>
    </StackLayout>
</template>
