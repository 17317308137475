<script setup lang="ts">
import { localFallback } from '@/locales/langUtils'
import partnerApi from '@/services/partners.service'
import { type EventModel } from '@/types/models/EventModel'
import type { ConfigType } from '@/types/models/PartnerConfigModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { AutocompleteField, StackLayout, TypedInput } from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'
import { onMounted, ref } from 'vue'

type Emits = {
    (e: 'update', value: Partial<EventModel>): void
}
const emit = defineEmits<Emits>()

const getPartners = (name?: string) =>
    partnerApi
        .getPartners(name ? { filters: { name } } : undefined)
        .then(res => (partnersOptions.value = res.data))
        .finally(() => (loadingEvents.value = false))
onMounted(() => getPartners())

const selectedPartner = ref<PartnerModel>()
const partnersOptions = ref<PartnerModel[]>([])
const handleSelect = (partner: PartnerModel) => {
    selectedPartner.value = partner
    emit('update', { partner_id: partner?.id, partner_config: null })
}

const handleChange = (value: string) => {
    loadingEvents.value = true
    debouncedQuestion(value)
}
const loadingEvents = ref(false)
const debouncedQuestion = useDebounceFn(
    (value?: string) => getPartners(value),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <AutocompleteField
            :label="$t('event.model.partnerName')"
            :options="partnersOptions"
            @typing="handleChange"
            @select="handleSelect($event as PartnerModel)"
            :clearable="false"
            class="full-width" />
        <StackLayout v-if="selectedPartner?.config">
            <TypedInput
                v-for="([itemKey, itemValue], key) in Object.entries(selectedPartner.config.event)"
                :key="key"
                :label="
                    (itemValue as ConfigType)?.label
                        ? localFallback((itemValue as ConfigType)?.label) ?? itemKey
                        : itemKey
                "
                :dataType="(itemValue as ConfigType)?.type ?? (itemValue as string)"
                @change="$emit('update', { partner_config: { [itemKey]: $event } })"
                class="full-width" />
        </StackLayout>
    </StackLayout>
</template>
