<script setup lang="ts">
import { generateRandomString } from '@/mockupData/mockupUtils'
import {
    CopyTypography,
    CreateButton,
    StackLayout,
    StringInputField
} from '@rfc/rfc-components-library'
import { computed } from 'vue'

const generatedFriendCode = generateRandomString(35)

const friendCode = computed({
    get: () => '',
    set: value => value
})
</script>

<template>
    <StackLayout direction="column" :gap="16" class="full-width">
        <CopyTypography
            :label="$t('organization.organizations.invite.yourFriendCode')"
            :value="generatedFriendCode" />
        <StackLayout direction="row" :gap="8">
            <StringInputField
                :label="$t('organization.organizations.invite.addFriendCode')"
                :modelValue="friendCode"
                class="full-width add-friend-code" />
            <CreateButton :label="$t('common.add')" />
        </StackLayout>
    </StackLayout>
</template>
