<script setup lang="ts">
import { QRCodeComponent, QRCodeScannerWrapper, StackLayout } from '@rfc/rfc-components-library'
import { darkModeLogo } from '../../../../../assets/assetsUtils'

const link = window.location.origin
</script>

<template>
    <StackLayout direction="column" :gap="8" class="full-width">
        <QRCodeComponent :logo="darkModeLogo" :value="link" />
        <QRCodeScannerWrapper />
    </StackLayout>
</template>

<style scoped lang="scss">
.qrCode-card {
    & {
        max-width: 400px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
</style>
