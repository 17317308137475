<script setup lang="ts">
import type { RoleModel } from '@/types/models/RoleModel'
import { InformationBaseCard, MultiautocompleteField } from '@rfc/rfc-components-library'
import { roleService } from '@rfc/rfc-components-library/services'
import { type SelectorObject } from '@rfc/rfc-components-library/types'
import { computed, onMounted, ref } from 'vue'

const rolesOptions = ref<RoleModel[]>([])

const props = defineProps<{ roles: string[] }>()
const emit = defineEmits<{ (e: 'change', roles: SelectorObject[]): void }>()

onMounted(() => {
    roleService
        .getRoles()
        .then(
            res => (rolesOptions.value = res.data?.map(item => ({ ...item, value: item.id })) ?? [])
        )
})

const rolesSelected = computed(() =>
    rolesOptions.value.length
        ? props.roles.reduce((prev: RoleModel[], current) => {
              const found = rolesOptions.value.find(option => option.name === current)
              return found ? [...prev, found] : prev
          }, [])
        : []
)

const handleSelect = (value: SelectorObject[]) => emit('change', value)
</script>

<template>
    <InformationBaseCard class="full-width" :label="$t('user.detail.rolesTitle')">
        <MultiautocompleteField
            :options="rolesOptions"
            :modelValue="rolesSelected"
            @change="handleSelect"
            :label="$t('user.detail.rolesTitle')"
            class="background-color-mute full-width" />
    </InformationBaseCard>
</template>
