<script setup lang="ts">
import { EDebounceTime } from '@/utils/debounceUtils'
import {
    LoadingSpinner,
    StackLayout,
    StringInputField,
    TitleTypography,
    TypographyItem
} from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

const results = ref<string[]>([])

const input = ref()
const loading = ref(false)
const handleChange = (value?: string) => {
    loading.value = true
    input.value = value
    debouncedQuestion()
}
const debouncedQuestion = useDebounceFn(() => getAnswers(), EDebounceTime.DEFAULT)
const getAnswers = () => {
    loading.value = false
    console.log('TODO : getAnswers', { input: input.value })
}
</script>

<template>
    <StackLayout direction="column" :gap="8" v-bind="$attrs">
        <StringInputField :label="$t('support.yourQuestion')" @change="handleChange" />
        <StackLayout direction="column" :gap="8">
            <TitleTypography :title="$t('support.results')" />
            <StackLayout v-show="loading" class="relative results-loading">
                <LoadingSpinner :isFullPage="false" :loading="!!loading" />
            </StackLayout>
            <StackLayout v-if="results.length">
                <TypographyItem v-for="(item, key) in results" :key="key" :label="item" />
            </StackLayout>
            <TypographyItem v-else :label="$t('common.noOption')" class="no-option-asked" />
        </StackLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.results-loading {
    min-height: 50px;
}
</style>
