import i18n from '@/locales/i18n'
import { EVENT_CHECKPOINT_ROUTES } from '@/router/routes'
import type { RouteType } from '@/types/Route'
import { ECheckpointsPerm } from '@/types/permissions/CheckpointsPermissionsEnum'
import CheckpointCreateView from '@/views/CheckpointsView/CheckpointCreateView.vue'
import CheckpointDetailView from '@/views/CheckpointsView/CheckpointDetailView.vue'

export enum ECheckpointRoutesNames {
    CHECKPOINT_TABLE = 'checkpointTable',
    CHECKPOINT_DETAIL = 'checkpointDetail',
    CHECKPOINT_CREATE = 'checkpointCreate'
}
export const ECheckpointRoutesNamesValues: string[] = [
    ECheckpointRoutesNames.CHECKPOINT_TABLE,
    ECheckpointRoutesNames.CHECKPOINT_DETAIL,
    ECheckpointRoutesNames.CHECKPOINT_CREATE
]

const routes: RouteType[] = [
    /* {
        path: `${EVENTS_ROUTES.detailUrl}`,
        name: ECheckpointRoutesNames.CHECKPOINT_TABLE,
        component: EventCheckpoints,
        meta: {
            title: (i18n.global.t('menu.events')),
            permission: ECheckpointsPerm.CHECKPOINTS_VIEW
        }
    }, */
    {
        path: `${EVENT_CHECKPOINT_ROUTES.detailUrl}/:checkpointId`,
        name: ECheckpointRoutesNames.CHECKPOINT_DETAIL,
        component: CheckpointDetailView,
        meta: {
            title: i18n.global.t('menu.events'),
            permission: ECheckpointsPerm.CHECKPOINTS_VIEW
        }
    },
    {
        path: `${EVENT_CHECKPOINT_ROUTES.detailUrl}/create`,
        name: ECheckpointRoutesNames.CHECKPOINT_CREATE,
        component: CheckpointCreateView,
        meta: {
            title: i18n.global.t('menu.events'),
            permission: ECheckpointsPerm.CHECKPOINTS_EDIT
        }
    }
]

export default routes
