<script setup lang="ts">
import { moment } from '@/locales/langUtils'
import type { TableEmits } from '@/types/components/CommonTableTypes'
import { ESortBy } from '@/types/enum/SortByEnum'
import { EStatus, EStatusLabels } from '@/types/enum/StatusEnum'
import type { ApiModel } from '@/types/models/ApiModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { DATE_FORMAT } from '@/utils/dateUtils'
import {
    FullTableLayout,
    HrefCreateButton,
    PermissionWrapper,
    StackLayout
} from '@rfc/rfc-components-library'
import type { PaginationDataType } from '@rfc/rfc-components-library/types'
import { useI18n } from 'vue-i18n'
import type { ApiFiltersType } from '../../types/filters/ApiFilters'
import ApiFilters from './ApiFilters.vue'

type Props = {
    data: PaginationDataType<ApiModel[]>
    loading?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums
    createButtonHref?: string
    editButtonHref?: string
    sort?: ESortBy
    filters?: ApiFiltersType
}
type Emits = TableEmits<ApiModel>

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const columns = [
    { value: 'id', title: t('api.model.id') },
    { value: 'name', title: t('api.model.name') },
    { value: 'active', title: t('api.model.active') },
    { value: 'expiration_date', title: t('api.model.expirationDate') }
]

const goToCreate = () => emit('goToCreate')
const goToDetail = (api: ApiModel) => emit('goToDetail', api)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <PermissionWrapper v-if="createButtonHref" hasPermission :permission="editPermission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('api.buttons.createApi')"
                @click="goToCreate" />
        </PermissionWrapper>
        <FullTableLayout
            :sortBy="sort"
            :columns="columns"
            :data="data"
            :loading="data.loading"
            @changeSortBy="$emit('changeSortBy', $event)"
            :perPage="data.per_page"
            :currentPage="data.current_page"
            :lastPage="data.last_page"
            :total="data.total"
            forcePropsCurrentPage
            @change:pageSize="handleChangePageSize"
            @change:pageNumber="handleChangePageNumber">
            <template #filters>
                <ApiFilters :filters="filters" />
            </template>

            <template #active="item">
                {{ $t(EStatusLabels[item.active ? EStatus.ENABLED : EStatus.DISABLED]) }}
            </template>
            <template #expiration_date="item">
                {{
                    item.expiration_date
                        ? moment(item.expiration_date).format(DATE_FORMAT).toString()
                        : $t('common.undefined')
                }}
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
