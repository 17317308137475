<script setup lang="ts">
import { LOGIN_URL } from '@/router'
import store from '@/store'
import { PasswordView } from '@rfc/rfc-components-library'
import { EAuthActions, EStoreModules } from '@rfc/rfc-components-library/store'
import type { PasswordType, ResetPasswordType } from '@rfc/rfc-components-library/types'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const loading = computed(() => store.value?.state.authentication?.loading)

const goToLogin = () => router.replace(LOGIN_URL)
const submitCreation = (password: ResetPasswordType & PasswordType) =>
    store.value
        ?.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.LOGIN}`, password)
        .then(res => {
            if (res.status === 200) router.replace(LOGIN_URL)
        })

const backgroundUrl = new URL('@/assets/background.jpg', import.meta.url).href
</script>

<template>
    <PasswordView
        :route="route"
        :loading="loading"
        :backgroundUrl="backgroundUrl"
        @clickSubmit="submitCreation"
        @clickSecondary="goToLogin" />
</template>
