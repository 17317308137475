<script setup lang="ts">
import SocketDetectionsWrapper from '@/socket/SocketDetectionsWrapper.vue'
import SocketDeviceAlertsWrapper from '@/socket/SocketDeviceAlertsWrapper.vue'
import type { TableEmits, TableProps } from '@/types/components/CommonTableTypes'
import { ESortByAlphabetical } from '@/types/enum/SortByEnum'
import { ETableMode, ETableModeIcons, ETableModeValues } from '@/types/enum/TableModeEnum'
import { type DeviceModel } from '@/types/models/DeviceModel'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type {
    SocketDetectionModel,
    SocketDeviceDetectionModel
} from '@/types/socket/SocketDetectionModel'
import {
    ButtonsGroupFilters,
    FullTableLayout,
    HrefCreateButton,
    PermissionWrapper,
    SortByFilter,
    StackLayout
} from '@rfc/rfc-components-library'
import {
    type AdvancedDeviceFilters,
    type DeviceFiltersType,
    type SelectorObject
} from '@rfc/rfc-components-library/types'
import { computed, ref } from 'vue'
import DeviceFilters from './Filters/DeviceFilters.vue'
import DeviceTableCardMode from './Table/DeviceTableCardMode.vue'
import DeviceTableListMode from './Table/DeviceTableListMode.vue'
import DeviceTableOutlookMode from './Table/DeviceTableOutlookMode.vue'

type Props = Omit<
    TableProps<DeviceModel, DeviceFiltersType, AdvancedDeviceFilters>,
    'createButtonHref' | 'editButtonHref'
> & {
    showNextEvent?: boolean
    showNextCheckpoint?: boolean
    createButtonHref?: string
    editButtonHref?: string
    forcePropsCurrentPage?: boolean
}
type Emits = TableEmits<DeviceModel, DeviceFiltersType, AdvancedDeviceFilters>

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const goToCreate = () => emit('goToCreate')
const goToDetail = (device: DeviceModel) => emit('goToDetail', device)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)

const tableMode = ref<number>(ETableMode.OUTLOOK)
const tableModes = [...ETableModeValues, ETableMode.OUTLOOK].map(item => ETableModeIcons[item])

const filtersTags = computed(() =>
    Object.entries({
        lowBattery: props.filters.lowBattery,
        lowSignal: props.filters.lowSignal
    })
        .map(([name, value]) => ({
            name,
            value
        }))
        .filter(item => !!item.value)
)

const handleRemoveTag = (value: SelectorObject) => {
    if (value.name) emit('removeTag', value)
}

const deviceIds = computed(
    () => !!props.data?.data?.length && props.data?.data?.map(item => item.device_id ?? item.id)
)

const deviceDetections = ref<SocketDeviceDetectionModel[]>([])
const handleDetectionsUpdate = (value: SocketDetectionModel) =>
    (deviceDetections.value = value.device_detections)

const deviceAlerts = ref<AlertSocketMessageModel[]>([])
const handleAlertsUpdate = (value: AlertSocketMessageModel[]) => (deviceAlerts.value = value)
</script>

<template>
    <SortByFilter :options="ESortByAlphabetical" />

    <SocketDeviceAlertsWrapper
        v-if="deviceIds"
        :device_ids="deviceIds"
        @update="handleAlertsUpdate" />
    <SocketDetectionsWrapper
        v-if="deviceIds"
        :device_ids="deviceIds"
        @update="handleDetectionsUpdate" />

    <StackLayout direction="column" :gap="8" class="device-table">
        <FullTableLayout
            isResponsiveSmallScreen
            :orderOptions="ESortByAlphabetical"
            :sortBy="sortBy"
            :advancedFilters="filtersTags"
            :data="data"
            translationPath="device.filters"
            @removeTag="handleRemoveTag"
            @search="$emit('search')"
            @changeSortBy="$emit('changeSortBy', $event)"
            :forcePropsCurrentPage="forcePropsCurrentPage"
            @change:pageSize="handleChangePageSize"
            @change:pageNumber="handleChangePageNumber">
            <template #filters>
                <DeviceFilters :filters="filters" @change="$emit('changeFilters', $event)" />
                <slot name="filters" />
            </template>
            <template #export>
                <ButtonsGroupFilters
                    :items="tableModes"
                    :value="tableMode"
                    @click="value => (tableMode = value as number)" />
            </template>

            <template #button>
                <PermissionWrapper v-if="createButtonHref" :permission="editPermission">
                    <HrefCreateButton
                        :href="createButtonHref"
                        :label="$t('device.table.createDevice')"
                        @click="goToCreate" />
                </PermissionWrapper>
            </template>
            <template
                v-if="tableMode === ETableMode.OUTLOOK || tableMode === ETableMode.CARD"
                #body="{ items }">
                <DeviceTableOutlookMode
                    v-if="tableMode === ETableMode.OUTLOOK"
                    :data="items"
                    :statuses="deviceAlerts"
                    :detections="deviceDetections"
                    :editPermission="editPermission"
                    :showNextEvent="showNextEvent"
                    :showNextCheckpoint="showNextCheckpoint"
                    @delete="$emit('delete', $event)"
                    :loading="loading">
                    <template #outlookMode="props">
                        <slot name="outlookMode" v-bind="props" />
                    </template>
                    <template #cardContent="device">
                        <slot
                            name="cardContent"
                            v-bind="{
                                ...device,
                                ...deviceAlerts.find(
                                    item => item.device_id === (device.device_id ?? device.id)
                                )
                            }" />
                    </template>
                </DeviceTableOutlookMode>
                <DeviceTableCardMode
                    v-else
                    :data="data?.data ?? []"
                    :statuses="deviceAlerts"
                    :detections="deviceDetections">
                    <template #cardContent="props">
                        <slot name="cardContent" v-bind="props" />
                    </template>
                </DeviceTableCardMode>
            </template>
            <template v-if="tableMode === ETableMode.LIST" #table>
                <DeviceTableListMode
                    :data="data"
                    :statuses="deviceAlerts"
                    :detections="deviceDetections" />
            </template>

            <!-- <template #dialogContent>
                <DevicesAdvancedFilters
                    :filters="advancedFilters"
                    @change="$emit('changeAdvancedFilters', $event)" />
                <slot name="dialogContent" />
            </template> -->
        </FullTableLayout>
    </StackLayout>
</template>

<style scoped lang="scss">
.device-table {
    overflow-y: auto;
}

.outlook-pagination {
    direction: ltr;
}
</style>
