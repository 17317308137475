import { EColors } from '@rfc/rfc-components-library/types'

export enum EDeviceStatus {
    OFFLINE = 0,
    ONLINE = 1,
    WARNING = 2
}

export const EDeviceStatusLabels = {
    [EDeviceStatus.OFFLINE]: 'device.status.offline',
    [EDeviceStatus.ONLINE]: 'device.status.online',
    [EDeviceStatus.WARNING]: 'device.status.warning'
}

export const EDeviceStatusColors = {
    [EDeviceStatus.OFFLINE]: EColors.ERROR,
    [EDeviceStatus.ONLINE]: EColors.SUCCESS,
    [EDeviceStatus.WARNING]: EColors.WARNING
}
