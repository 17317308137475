import type { PaginationDataType } from '@rfc/rfc-components-library/types'
import { EPaginationOptions } from '@rfc/rfc-components-library/types'

export const getQueryParams = (data: PaginationDataType<unknown>) => ({
    per_page: data.per_page,
    page: data.current_page
})

export const isCurrentPageOverTotal = (
    total?: number,
    pageSize?: number,
    currentPage?: number,
    lastPage?: number
) =>
    currentPage === lastPage &&
    Math.ceil((total ?? 1) / (pageSize ?? EPaginationOptions.DEFAULT)) < (lastPage ?? 1)
