import i18n from '@/locales/i18n'
import partnerApi from '@/services/partners.service'
import type { PaginationDataType, PaginationType } from '@/types/PaginationType'
import { EHandler } from '@/types/enum/HandlerEnum'
import { EPaginationOptions } from '@/types/enum/PaginationOptionsEnum'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { getPageSize, setDocumentAndTitle } from '@/utils/routeUtils'
import { EAlertActions } from '@rfc/rfc-components-library/store'
import { ref, type Ref } from 'vue'
import type { Commit, Dispatch } from 'vuex'
import { EStoreModules } from '../storeType'
import { EPartnerActions, EPartnerMutations, type PartnerState } from './PartnerStoreTypes'

const state: Ref<PaginationDataType<PartnerState>> = ref({
    data: { partners: [] },
    current_page: 1,
    last_page: 0,
    total: 0,
    to: 0,
    from: 0,
    per_page: getPageSize() ?? EPaginationOptions.DEFAULT,

    loading: false
})

const getters = {
    getPartners: () => state.value.data.partners,
    loading: () => state.value.loading
}

const actions = {
    async [EPartnerActions.FETCH_PARTNER](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        partnerId: number
    ) {
        commit(EPartnerMutations.SET_LOADING, true)
        return await partnerApi
            .getPartner(partnerId)
            .then(res => {
                commit(EPartnerMutations.SET_PARTNER, res.data)
                setDocumentAndTitle(res.data.name)
                return res.data
            })
            .finally(() => commit(EPartnerMutations.SET_LOADING, false))
    },

    async [EPartnerActions.FETCH_PARTNERS](
        { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
        params: Partial<PaginationType>
    ) {
        commit(EPartnerMutations.SET_LOADING, true)
        partnerApi
            .getPartners(params)
            .then(data => {
                commit(EPartnerMutations.SET_PARTNERS, data.data)
                commit(EPartnerMutations.SET_TOTAL, data)
            })
            .finally(() => commit(EPartnerMutations.SET_LOADING, false))
    },

    [EHandler.HANDLE_SUCCESS](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.SUCCESS,
                message: i18n.global.t(`partner.api.success.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    },
    [EHandler.HANDLE_ERROR](
        { dispatch }: { dispatch: Dispatch },
        { name, key }: { name: string; key: string }
    ) {
        dispatch(
            `${EStoreModules.ALERT}/${EAlertActions.QUEUE_ITEM}`,
            {
                action: EAlertActions.ERROR,
                message: i18n.global.t(`partner.api.error.${key}`, {
                    name
                })
            },
            { root: true }
        )
        return { name }
    }
}

const mutations = {
    [EPartnerMutations.SET_PARTNERS](_: PartnerState, partners: any) {
        state.value.data.partners = partners
    },

    [EPartnerMutations.SET_PARTNER](_: PartnerState, partner: any) {
        state.value.data.partner = partner
    },

    [EPartnerMutations.SET_TOTAL](_: PartnerState, data: PaginationDataType<PartnerModel>) {
        state.value.current_page = data.current_page
        state.value.total = data.total
        state.value.last_page = data.last_page
        state.value.to = data.to
        state.value.from = data.from
        state.value.per_page = data.per_page
    },

    [EPartnerMutations.SET_LOADING](_: PartnerState, loading: boolean) {
        state.value.loading = loading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
