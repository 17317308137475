import type { AlertProps } from '@/types/components/AlertComponent'
import type { NotificationProps } from '@/types/components/NotificationComponent'
import { type StoreState } from '@rfc/rfc-components-library/store'
import type { PaginationDataType } from '@rfc/rfc-components-library/types'
import type { CheckpointState } from './checkpointStore/CheckpointStoreTypes'
import type { EventState } from './eventStore/EventStoreTypes'
import type { FiltersState } from './filtersStore/FiltersStoreTypes'
import type { PartnerState } from './partnersStore/PartnerStoreTypes'

export type UrtimeStoreState = StoreState & {
    alert: AlertProps
    checkpoint: PaginationDataType<CheckpointState>
    event: PaginationDataType<EventState>
    filters: FiltersState
    notification: NotificationProps
    partner: PaginationDataType<PartnerState>
}

export enum EStoreModules {
    ALERT = 'alert',
    API = 'api',
    AUTHENTICATION = 'authentication',
    CHECKPOINT = 'checkpoint',
    ORGANIZATION = 'organization',
    DETECTION = 'detection',
    DEVICE = 'device',
    DEVICE_CONFIG = 'deviceConfig',
    EVENT = 'event',
    FILTERS = 'filters',
    LOADING = 'loading',
    NOTIFICATION = 'notification',
    PAGINATION = 'pagination',
    PARTNER = 'partner',
    PERMISSION = 'permission',
    QUESTION = 'question',
    ROLE = 'role',
    TECHNICIAN = 'technician',
    TICKET = 'ticket',
    USER = 'user',
    TOPIC = 'topic'
}
