<script setup lang="ts">
import store from '@/store'
import {
    EPasswordValidationLabels,
    type EPasswordValidation
} from '@/types/enum/PasswordValidationEnum'
import { passwordValidation } from '@/utils/passwordUtils'
import { GuestLayout, StackLayout, StringInputField } from '@rfc/rfc-components-library'
import { type PasswordType } from '@rfc/rfc-components-library/types'
import { computed, ref, watchEffect } from 'vue'

type Props = {
    primaryLabel: string
    secondaryLabel?: string
    title: string
}

type Emits = {
    (e: 'clickSecondary'): void
    (e: 'clickSubmit', password?: string, password_confirmation?: string): void
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const loading = computed(() => store.value?.state.authentication.loading)

const form = ref<PasswordType>({
    password: undefined,
    password_confirmation: undefined
})

const submitForm = () => {
    submitted.value = true
    if (
        validatedPasswordHasErrors.value &&
        form.value.password !== form.value.password_confirmation
    )
        return // TODO : Alerte
    submitted.value = false
    emit('clickSubmit', form.value.password, form.value.password_confirmation)
}

const validatedPassword = computed(() =>
    passwordValidation(form.value.password, form.value.password_confirmation)
)
const validatedPasswordHasErrors = computed(() =>
    Object.values(validatedPassword.value).find(item => !item)
)
const errors = computed(() => ({
    passwordError: submitted.value && !form.value.password,
    password_confirmationError:
        submitted.value &&
        (!form.value.password || form.value.password !== form.value.password_confirmation)
}))

const submitted = ref(false)
watchEffect(() => {
    if (Object.values(validatedPassword.value).find(item => !item)) return (submitted.value = true)
    submitted.value = false
})
</script>

<template>
    <GuestLayout
        :title="title"
        :primaryLabel="primaryLabel"
        :secondaryLabel="secondaryLabel ?? $t('authentication.back')"
        @clickSubmit="submitForm"
        @clickSecondary="$emit('clickSecondary')"
        class="password-guest-layout">
        <template #content>
            <form method="post" name="password">
                <StackLayout :gap="16" direction="column">
                    <span>
                        {{ $t('authentication.createPassword.contentTitle') }}
                        <slot name="title" />
                    </span>
                    <StringInputField
                        :label="$t('authentication.createPassword.password')"
                        @keyup.enter="submitForm"
                        v-model="form.password"
                        @change="value => (form.password = value)"
                        :placeholder="$t('authentication.createPassword.password')"
                        inputType="password"
                        required
                        autocomplete="new-password"
                        :hasError="errors.passwordError"
                        v-bind:password-reveal="true" />
                    <StringInputField
                        :label="$t('authentication.createPassword.password_confirmation')"
                        @keyup.enter="submitForm"
                        v-model="form.password_confirmation"
                        @change="value => (form.password_confirmation = value)"
                        :placeholder="$t('authentication.createPassword.password_confirmation')"
                        inputType="password"
                        required
                        :hasError="errors.password_confirmationError"
                        v-bind:password-reveal="true" />
                    <span>{{ $t('authentication.passwordRules.content') }}</span>
                    <li
                        v-for="([key, value], index) in Object.entries(validatedPassword)"
                        :key="index"
                        :class="value ? 'success' : 'error'">
                        {{ $t(EPasswordValidationLabels[key as EPasswordValidation]) }}
                    </li>
                </StackLayout>
            </form>
        </template>
    </GuestLayout>
</template>
