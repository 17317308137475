import type { ThemeDefinition } from 'vuetify'
import { commonColors } from './common'

export const dark: ThemeDefinition = {
    dark: false,
    colors: {
        ...commonColors,
        secondary: '#242424'
    }
}
