<script setup lang="ts">
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { isMobile, isMobileEvent } from '@/utils/viewsUtils'
import {
    CreateButton,
    GridLayout,
    PermissionWrapper,
    StackLayout
} from '@rfc/rfc-components-library'
import { type UserModel } from '@rfc/rfc-components-library/types'
import { ref } from 'vue'
import UserInformations from './Detail/components/UserInformations.vue'
import UserRoles from './Detail/components/UserRoles.vue'

type Props = {
    label: string
    submitted?: boolean
    data: Partial<UserModel>
    editPermission?: PermissionsEnums
    hasPermission?: boolean
    loading?: boolean
}

defineProps<Props>()
const emit = defineEmits<{
    (e: 'click'): void
    (e: 'update', userValue: Partial<UserModel>): void
}>()

const updateUserValue = (value: Partial<UserModel>) => emit('update', value)

const maxItemPerRow = ref(isMobile() ? 1 : 2)
isMobileEvent(e => (maxItemPerRow.value = e.matches ? 1 : 2))
</script>

<template>
    <StackLayout class="margin-bottom" direction="column" :gap="16" isResponsive>
        <PermissionWrapper :permission="editPermission" :hasPermission="hasPermission">
            <CreateButton :loading="loading" :label="label" @click="$emit('click')" />
        </PermissionWrapper>
        <GridLayout :maxItemPerRow="maxItemPerRow ?? 1" :gap="16">
            <UserInformations
                :data="data"
                :submitted="submitted"
                @change="updateUserValue($event)" />
            <slot />
        </GridLayout>
        <StackLayout :gap="16" isResponsive class="full-width">
            <UserRoles
                class="full-width fit-height"
                :roles="data.roles ?? []"
                @change="value => updateUserValue({ roles: value.map(item => item.name!) })" />
            <slot name="update" />
        </StackLayout>
    </StackLayout>
</template>
