<script setup lang="ts">
import type { OrganizationModel } from '@/types/models/OrganizationModel'
import { EditableStringField, InformationBaseCard, StackLayout } from '@rfc/rfc-components-library'
import { ref } from 'vue'

type Props = {
    data: OrganizationModel
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<OrganizationModel>): void
}
defineEmits<Emits>()
const editMode = ref(false)
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <InformationBaseCard
            class="full-width input-information-card"
            :label="$t('organization.informations.general')">
            <StackLayout direction="column" :gap="8" class="full-width">
                <StackLayout direction="column" :gap="8">
                    <EditableStringField
                        :label="$t('organization.model.name')"
                        :value="data.name"
                        :editMode="editMode"
                        @change="name => $emit('change', { name })"
                        required
                        class="full-width" />
                    <EditableStringField
                        :label="$t('organization.model.socialReason')"
                        :value="data.social_reason"
                        :editMode="editMode"
                        @change="social_reason => $emit('change', { social_reason })"
                        required
                        class="full-width" />
                </StackLayout>
            </StackLayout>
        </InformationBaseCard>
    </StackLayout>
</template>
