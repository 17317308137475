<script setup lang="ts">
import { moment } from '@/locales/langUtils'
import { EVENTS_ROUTES } from '@/router/routes'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { DATE_FORMAT } from '@/utils/dateUtils'
import {
    BaseTable,
    ClickableTypography,
    StackLayout,
    TypographyItem,
    TypographyWithPrefix
} from '@rfc/rfc-components-library'
import type { DeviceModel, PaginationDataType } from '@rfc/rfc-components-library/types'
import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DeviceLowSignal from '../Typography/DeviceLowSignal.vue'
import DeviceOnlineStatus from '../Typography/DeviceOnlineStatus.vue'

const { t } = useI18n()

type Props = {
    data?: PaginationDataType<DeviceModel[]>
    statuses: AlertSocketMessageModel[]
    detections?: SocketDeviceDetectionModel[]
    loading?: boolean
    deletePermission?: PermissionsEnums
    editPermission?: PermissionsEnums

    editButtonHref?: string
    hrefKey?: string
}

const props = defineProps<Props>()
type Emits = {
    (e: 'click', device: DeviceModel): void
    (e: 'delete', device: DeviceModel): void
}
const emit = defineEmits<Emits>()

const columns = [
    { value: 'name', title: t('device.table.name') },
    { value: 'device_type_name', title: t('device.table.deviceType') },
    { value: 'event.name', title: t('device.table.currentEventTitle') }
]

const devicesData = computed(() => ({
    ...props.data,
    data:
        props.data?.data?.map(item => ({
            ...item,
            ...(props.statuses?.find(status => status.device_id === item.device_id) ?? {})
        })) ?? []
}))

watch(
    () => devicesData.value,
    newValue => console.log({ newValue })
)
</script>

<template>
    <BaseTable :data="devicesData" :columns="columns" :loading="loading">
        <template #name="item">
            <StackLayout direction="column" :gap="2">
                <ClickableTypography
                    v-if="item.name"
                    :label="item.name"
                    :href="`${editButtonHref}/${item[hrefKey ?? 'id']}`" />
                <TypographyWithPrefix
                    :prefix="$t('device.model.serialNumber')"
                    :label="item.serial_number ?? undefined"
                    boldLabel />
            </StackLayout>
        </template>
        <template #event="item">
            <StackLayout v-if="item.event" direction="column" :gap="2">
                <ClickableTypography
                    :label="item.event.name"
                    :href="`${EVENTS_ROUTES.buildUrl(item.event.id)}`" />
                <TypographyItem :label="moment(item.event.start_at).format(DATE_FORMAT)" />
            </StackLayout>
            <TypographyItem v-else label="" />
        </template>
        <template #alerts="item">
            <StackLayout direction="column">
                <DeviceOnlineStatus :status="parseInt(item.status)" />
                <DeviceLowSignal :lowSignal="item.lowSignal" />
            </StackLayout>
        </template>
    </BaseTable>
</template>
