<script setup lang="ts">
import { StackLayout, TitleTypography, TypographyItem } from '@rfc/rfc-components-library'
import { onMounted, ref } from 'vue'

const frequentlyAsked = ref<string[]>([])

onMounted(() => {
    console.log('TODO : Get frequently asked')
})
</script>

<template>
    <StackLayout direction="column" :gap="8" v-bind="$attrs">
        <TitleTypography :title="$t('support.frequentlyAsked')" />
        <StackLayout v-if="frequentlyAsked.length" class="results-loading">
            <TypographyItem v-for="(item, key) in frequentlyAsked" :key="key" :label="item" />
        </StackLayout>
        <TypographyItem v-else :label="$t('common.noOption')" class="no-option-asked" />
    </StackLayout>
</template>

<style scoped lang="scss">
.results-loading {
    min-height: 50px;
}
</style>
