import { EColors } from '@rfc/rfc-components-library/types'
import { ref } from 'vue'

export const isSmallScreen = () => window.matchMedia('(max-width: 460px)').matches
export const isSmallScreenEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(max-width: 460px)').addEventListener('change', callback)

export const isMobile = () => window.matchMedia('(max-width: 760px)').matches
export const isMobileEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(max-width: 760px)').addEventListener('change', callback)

export const isTablet = () => window.matchMedia('(max-width: 1023px)').matches
export const isTabletEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(max-width: 1023px)').addEventListener('change', callback)

export const isDesktop = () => window.matchMedia('(max-width: 1216px)').matches
export const isDesktopEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(max-width: 1216px)').addEventListener('change', callback)

export const isWidescreen = () => window.matchMedia('(max-width: 1407px)').matches
export const isWidescreenEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(max-width: 1407px)').addEventListener('change', callback)

export const isFullHd = () => window.matchMedia('(min-width: 1408px)').matches
export const isFullHdEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(min-width: 1408px)').addEventListener('change', callback)

export const isDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches
export const isDarkEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', callback)

export const isLight = () => window.matchMedia('(prefers-color-scheme: light)').matches
export const isLightEvent = (callback: (ev: MediaQueryListEvent) => void) =>
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', callback)

export const getCurrentThemeMode = ref(
    (document.documentElement.getAttribute('data-theme') as EColors.LIGHT | EColors.DARK) ??
        EColors.DARK
)
export const setCurrentThemeMode = (value: EColors.LIGHT | EColors.DARK) => {
    getCurrentThemeMode.value = value
    document.documentElement.setAttribute('data-theme', value)
}
