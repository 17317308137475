import { defaultPasswordValidation, type PasswordValidation } from '@/types/PasswordValidation'
import { REGEX_LOWERCASE, REGEX_NUMBER, REGEX_SPECIAL, REGEX_UPPERCASE } from './regexUtils'

export const passwordValidation = (password?: string, confirmPasssword?: string) => {
    const validatedPassword: PasswordValidation = defaultPasswordValidation
    validatedPassword.hasNoWhitespace = !password?.match(/\s/)
    validatedPassword.hasUppercase = !!password?.match(REGEX_UPPERCASE)
    validatedPassword.hasLowercase = !!password?.match(REGEX_LOWERCASE)
    validatedPassword.hasNumber = !!password?.match(REGEX_NUMBER)
    validatedPassword.hasSpecial = !!password?.match(REGEX_SPECIAL)
    validatedPassword.hasMinLength = (password?.length ?? 0) >= 8
    validatedPassword.passwordMatches =
        !!password?.length && !!confirmPasssword?.length && password === confirmPasssword
    return validatedPassword
}
