<script setup lang="ts">
import { LOGIN_URL } from '@/router'
import store from '@/store'
import {
    GuestLayout,
    CircleProgressBar,
    SecondaryButton,
    StackLayout
} from '@rfc/rfc-components-library'
import { EAuthActions, EStoreModules } from '@rfc/rfc-components-library/store'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

const COUNT_START = 60
const PROGRESS_START = 100

type Props = {
    primaryLabel: string
    title: string
}

defineProps<Props>()

const loading = computed(() => store.value?.state.authentication.loading)
const email = computed(() => route.params.email)

const goBack = () => router.back()
const goToLogin = () => router.push(LOGIN_URL)

const timer = ref(COUNT_START)
const progress = ref(PROGRESS_START)
const timeoutRef = ref()

const timerCountdown = () => {
    if (timer.value > 0)
        timeoutRef.value = setTimeout(() => {
            timer.value = timer.value - 1
            progress.value = Math.round((timer.value * PROGRESS_START) / COUNT_START)
            timerCountdown()
        }, 1000)
}

const resendEmail = () => {
    if (!email.value) return //TODO : Alerte
    store.value
        ?.dispatch(
            `${EStoreModules.AUTHENTICATION}/${EAuthActions.FORGOT_PASSWORD}`,
            email.value.toString()
        )
        .then(res => {
            if (res.status === 200) {
                timer.value = COUNT_START
                timerCountdown()
            }
        })
}

onMounted(() => {
    timerCountdown()
})
onUnmounted(() => clearTimeout(timeoutRef.value))
</script>

<template>
    <GuestLayout
        :title="$t('authentication.sendLink.title')"
        :primaryLabel="$t('authentication.backToLogin')"
        :secondaryLabel="$t('authentication.back')"
        @clickSubmit="goToLogin"
        @clickSecondary="goBack"
        class="password-guest-layout">
        <template #content>
            <form method="post" name="password">
                <StackLayout :gap="16" direction="column">
                    <span>
                        {{ $t('authentication.sendLink.contentTitle') }}
                    </span>
                    <span class="bold align-self-center">
                        {{ email }}
                    </span>
                    <StackLayout direction="column" :gap="8" class="align-self-center">
                        <CircleProgressBar
                            :progressValue="progress"
                            :value="timer"
                            class="align-self-center" />
                        <SecondaryButton
                            :disabled="timer !== 0"
                            class="disabled-button"
                            :label="$t('authentication.sendLink.resendLink')"
                            @click="resendEmail" />
                    </StackLayout>
                    <span>{{ $t('authentication.sendLink.content') }}</span>
                    <li>
                        {{ $t('authentication.sendLink.checkSpam') }}
                    </li>
                    <li>
                        {{ $t('authentication.sendLink.redoSend') }}
                    </li>
                </StackLayout>
            </form>
        </template>
    </GuestLayout>
</template>
