<script setup lang="ts">
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { CreateButton } from '@rfc/rfc-components-library'
import { ref } from 'vue'
import EventCreateCheckpointDialog from './EventCreateCheckpointDialog.vue'

type Props = {
    eventId: number
    partnerConfig?: PartnerModel
    loading?: boolean
}
defineProps<Props>()
const emit = defineEmits<{ (e: 'refresh'): void }>()

const createCheckpointOpen = ref(false)
const handleDialogOpen = (value: boolean) => (createCheckpointOpen.value = value)

const refresh = () => {
    createCheckpointOpen.value = false
    emit('refresh')
}
</script>

<template>
    <CreateButton
        :loading="loading"
        :label="$t('event.button.addCheckpoint')"
        @click="handleDialogOpen(true)" />
    <EventCreateCheckpointDialog
        :open="createCheckpointOpen"
        :eventId="eventId"
        :partnerConfig="partnerConfig"
        @refresh="refresh"
        @close="handleDialogOpen(false)" />
</template>
