import i18n from '@/locales/i18n'
import { ESortByLabels, type ESortBy } from '@/types/enum/SortByEnum'
import { computed } from 'vue'

export const orderByEnumToOptions = (options: ESortBy[]) =>
    computed(() =>
        options.map(item => ({ label: i18n.global.t(ESortByLabels[item]), value: item }))
    )

export const getValuesFromEnum = <E>(
    value: Record<keyof E, number | string> & { [k: number]: string }
): string[] => Object.values(value)
