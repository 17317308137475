<script setup lang="ts">
import store from '@/store'
import { PrimaryButton } from '@rfc/rfc-components-library'
import { EAuthActions, EStoreModules } from '@rfc/rfc-components-library/store'

type Props = {
    email: string
}
const props = defineProps<Props>()

const resetPassword = () =>
    store.value?.dispatch(
        `${EStoreModules.AUTHENTICATION}/${EAuthActions.FORGOT_PASSWORD}`,
        props.email
    )
</script>

<template>
    <PrimaryButton
        :label="$t('user.buttons.resetPassword')"
        @click="resetPassword"
        class="reset-password-button" />
</template>

<style scoped lang="scss">
.reset-password-button {
    height: auto;
    & :deep(span) {
        white-space: normal;
    }
}
</style>
