<script setup lang="ts">
import ApiDetailView from '@/components/Api/ApiDetailView.vue'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { defaultApiModel, type ApiModel } from '@/types/models/ApiModel'
import { EApiActions } from '@rfc/rfc-components-library/store'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const loading = computed(() => store.value?.state.organization.apis.loading)
const apiId = ref(route.params.id.toString())
const submitted = ref(false)

const data = ref<Partial<ApiModel>>({ ...defaultApiModel })

const getApi = () =>
    store.value
        ?.dispatch(`${EStoreModules.API}/${EApiActions.FETCH_API}`, apiId.value)
        .then(res => (data.value = res))

onMounted(() => {
    if (typeof apiId.value === 'string') getApi()
})

const updateApi = (value: Partial<ApiModel>) => (data.value = value)

const save = () => {
    submitted.value = true
    if (!data.value.name) return
    store.value
        ?.dispatch(`${EStoreModules.API}/${EApiActions.UPDATE_API}`, {
            apiId: apiId.value,
            apiData: data.value
        })
        .then(() => (submitted.value = false))
}
</script>

<template>
    <ApiDetailView
        :data="data"
        :buttonLabel="$t('common.saveModifications')"
        :loading="!!store?.state.organization.apis.loading"
        @click="save"
        @update="updateApi" />
</template>
