import {
    CREATE_PASSWORD_ROUTE,
    FORGOT_PASSWORD_ROUTE,
    RESET_PASSWORD_ROUTE,
    SEND_LINK_PWD_ROUTE
} from '@/router/routes'
import type { RouteType } from '@/types/Route'
import LoginView from '@/views/LoginView/LoginView.vue'
import PasswordCreate from '@/views/PasswordView/PasswordCreate.vue'
import PasswordForgotForm from '@/views/PasswordView/PasswordForgotForm.vue'
import PasswordReset from '@/views/PasswordView/PasswordReset.vue'
import PasswordSendLinkView from '@/views/PasswordView/PasswordSendLinkView.vue'

export enum EAuthenticationRoutes {
    LOGIN = 'login',
    CREATE_PWD = 'createPassword',
    RESET_PWD = 'resetPassword',
    FORGOT_PWD = 'forgotPasswordEmail',
    LINK_CONFIRMATION = 'sendLinkConfirmation'
}

const routes: RouteType[] = [
    {
        path: '/login',
        name: EAuthenticationRoutes.LOGIN,
        component: LoginView,
        meta: {
            title: 'authentication.title.title',
            guestRoute: true
        }
    },
    {
        path: CREATE_PASSWORD_ROUTE,
        name: EAuthenticationRoutes.CREATE_PWD,
        component: PasswordCreate,
        meta: {
            title: 'authentication.createPassword.title',
            guestRoute: true
        }
    },
    {
        path: RESET_PASSWORD_ROUTE,
        name: EAuthenticationRoutes.RESET_PWD,
        component: PasswordReset,
        meta: {
            title: 'authentication.title.resetPassword',
            guestRoute: true
        }
    },
    {
        path: FORGOT_PASSWORD_ROUTE,
        name: EAuthenticationRoutes.FORGOT_PWD,
        component: PasswordForgotForm,
        meta: {
            title: 'authentication.forgotPassword.title',
            guestRoute: true
        }
    },
    {
        path: SEND_LINK_PWD_ROUTE,
        name: EAuthenticationRoutes.LINK_CONFIRMATION,
        component: PasswordSendLinkView,
        meta: {
            title: 'authentication.title.sendLinkConfirmation',
            guestRoute: true
        }
    }
]

export default routes
