<script setup lang="ts">
import { type CheckpointModel } from '@/types/models/CheckpointModel'
import { MultilineField, StackLayout, StringInputField } from '@rfc/rfc-components-library'
import { computed } from 'vue'

type Props = {
    eventId: number
    data: Partial<CheckpointModel>
    isSubmitted: boolean
}
const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'change', value: Partial<CheckpointModel>): void
}>()

const checkpointData = computed({
    get: () => props.data,
    set: value => emit('change', value)
})

const errors = computed(() => ({
    checkpointNameError: props.isSubmitted && !checkpointData.value.name
}))
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <StringInputField
            :label="$t('checkpoint.model.name')"
            v-model="checkpointData.name"
            @change="value => (checkpointData.name = value)"
            :placeholder="$t('checkpoint.model.name')"
            :hasError="errors.checkpointNameError"
            required
            class="full-width" />
        <MultilineField
            :label="$t('checkpoint.model.comments')"
            v-model="checkpointData.comments"
            @change="value => (checkpointData.comments = value)"
            :placeholder="$t('checkpoint.model.comments')"
            class="full-width" />
    </StackLayout>
</template>
