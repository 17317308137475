<script setup lang="ts">
import type { AdminUserFiltersType } from '@/types/filters/AdminFilters'
import { EDebounceTime } from '@/utils/debounceUtils'
import { StackLayout, StringInputField } from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'

type Props = {
    name?: string
    email?: string
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: AdminUserFiltersType): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value: AdminUserFiltersType) => debouncedName(value)

const debouncedName = useDebounceFn(
    (value: AdminUserFiltersType) => emit('change', value),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout :gap="8" class="full-width" isResponsiveSmallScreen>
        <StringInputField
            :label="$t('user.filters.name')"
            :modelValue="name"
            @change="handleTyping({ name: $event })" />
        <StringInputField
            :label="$t('user.filters.email')"
            :modelValue="email"
            @change="handleTyping({ email: $event })" />
        <slot />
    </StackLayout>
</template>
