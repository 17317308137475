<script setup lang="ts">
import type { DropZoneData } from '@/types/components/DropZoneData'
import type { CheckpointModel } from '@/types/models/CheckpointModel'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import {
    BaseCard,
    DeleteButton,
    PermissionWrapper,
    StackLayout,
    TitleTypography,
    TypographyItem
} from '@rfc/rfc-components-library'
import { type Position } from '@vueuse/core'
import DeviceCheckpointCard from './DeviceCheckpointCard.vue'
import DeviceDropZone from './DeviceDropZone.vue'

type Props = {
    data: CheckpointModel
    dropZoneData?: DropZoneData
    detections?: DetectionModel[]
    deletePermission?: PermissionsEnums
    editPermission?: PermissionsEnums
}
const props = defineProps<Props>()
type Emits = {
    (e: 'delete', checkpoint: CheckpointModel): void
    (e: 'click', checkpoint?: CheckpointModel): void
    (e: 'dragStart', device?: AssociatedDeviceModel, checkpointId?: number): void
    (e: 'dragEnd', position?: Position): void
    (e: 'close'): void
    (e: 'refresh'): void
}
const emit = defineEmits<Emits>()

const handleGoTo = () => emit('click', props.data)
</script>

<template>
    <DeviceDropZone
        :checkpoint="data"
        :dropZoneData="dropZoneData"
        @close="$emit('close')"
        @refresh="$emit('refresh')"
        v-slot="props">
        <BaseCard
            class="flex primary-border color-text full-width checkpoint-card pointer"
            @click="handleGoTo"
            v-bind="props">
            <template #title>
                <TitleTypography :title="data.name" />
            </template>
            <template #content>
                <StackLayout direction="column" :gap="8" isResponsiveSmallScreen>
                    <StackLayout v-if="data.associates?.length" :gap="4" class="flex-wrap">
                        <StackLayout
                            v-for="(device, key) in data.associates"
                            :key="key"
                            direction="column"
                            :gap="4"
                            class="fit-content checkpoint-device-card"
                            isResponsiveSmallScreen>
                            <DeviceCheckpointCard
                                :device="device"
                                @dragStart="$emit('dragStart', $event, data.id)"
                                @dragEnd="$emit('dragEnd', $event)" />
                        </StackLayout>
                    </StackLayout>
                    <TypographyItem v-else :label="$t('event.checkpoint.noDeviceAssociated')" />
                    <StackLayout direction="column" class="device-card-footer"></StackLayout>
                </StackLayout>
            </template>
            <template #footer>
                <StackLayout class="full-width">
                    <PermissionWrapper :permission="deletePermission">
                        <DeleteButton
                            class="full-width left-button"
                            @click="$emit('delete', data)" />
                    </PermissionWrapper>
                </StackLayout>
            </template>
        </BaseCard>
    </DeviceDropZone>
</template>

<style scoped lang="scss">
.checkpoint-card {
    flex-direction: column;

    & :deep(.card-header-title) {
        & .title {
            font-size: large;
            color: var(--color-text);
        }
    }

    & :deep(.card-content) {
        height: 100%;

        & hr {
            margin-left: unset;
            margin-right: unset;
            border-style: unset;
        }
    }

    & :deep(.card-header-title .subtitle) {
        color: var(--color-text-secondary);
    }

    & :deep(.card-footer) {
        flex: auto;
    }

    & :deep(.card-header-title),
    & :deep(.card-content) {
        padding: 0.5em;
    }
}
.device-card {
    flex-direction: column;
    cursor: pointer;
    pointer-events: all;
}

.checkpoint-device-card {
    cursor: default;
}

.detection-class {
    & :deep(.no-detection) {
        white-space: nowrap;
    }
}
</style>
