export enum EColors {
    WHITE = 'white',
    BLACK = 'black',
    LIGHT = 'light',
    DARK = 'dark',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    ERROR = 'error'
}

export type Colors =
    | EColors.WHITE
    | EColors.BLACK
    | EColors.LIGHT
    | EColors.DARK
    | EColors.PRIMARY
    | EColors.SECONDARY
    | EColors.INFO
    | EColors.SUCCESS
    | EColors.WARNING
    | EColors.ERROR
    | EColors.ERROR
