import { EOrganizationActions } from './organizationStoreTypes'

const actions = {
    [EOrganizationActions.ONBOARDING]: () => {},
    [EOrganizationActions.GET_ONBOARDING]: () => {},
    [EOrganizationActions.VALIDATE_ONBOARDING]: () => {}
}

export default {
    namespaced: true,
    actions
}
