<script setup lang="ts">
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { defaultRoleModel, type RoleModel } from '@/types/models/RoleModel'
import { EAdminPerm } from '@/types/permissions/AdminPermissionsEnum'
import { ERoleActions } from '@rfc/rfc-components-library/store'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import RolesDetail from './components/RolesDetail.vue'

const route = useRoute()
const roleId = ref(route.params.id.toString())

const data = ref<Partial<RoleModel>>({ ...defaultRoleModel })
const getRole = () =>
    store.value
        ?.dispatch(`${EStoreModules.ROLE}/${ERoleActions.FETCH_ROLE}`, roleId.value)
        .then(res => (data.value = res))

onMounted(() => getRole())

const save = () =>
    store.value
        ?.dispatch(`${EStoreModules.ROLE}/${ERoleActions.UPDATE_ROLE}`, {
            roleId: roleId.value,
            roleData: data.value
        })
        .then(() => getRole())

const updateRole = (value: Partial<RoleModel>) => (data.value = { ...data.value, ...value })
</script>

<template>
    <RolesDetail
        :data="data"
        :buttonLabel="$t('common.saveModifications')"
        :permission="EAdminPerm.ADMIN_ROLES"
        :loading="store?.state.role.loading"
        @click="save"
        @update="updateRole" />
</template>
