<script setup lang="ts">
import { EDebounceTime } from '@/utils/debounceUtils'
import { AutocompleteField } from '@rfc/rfc-components-library'
import { organizationService } from '@rfc/rfc-components-library/services'
import type { OrganizationModel } from '@rfc/rfc-components-library/types'
import { useDebounceFn } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

type Props = {
    organizationId?: number
    label?: string
}
const props = defineProps<Props>()
type Emits = {
    (e: 'select', organizationValue: OrganizationModel): void
}
defineEmits<Emits>()

const getOrganizations = (name?: string) =>
    organizationService
        .getOrganizations(name ? { filters: { name } } : {})
        .then(res => (organizationsOptions.value = res.data ?? []))
        .finally(() => (loadingOrganization.value = false))
onMounted(() => getOrganizations())

const handleTyping = (value?: string) => {
    loadingOrganization.value = true
    debouncedOrganization(value)
}
const loadingOrganization = ref(false)
const debouncedOrganization = useDebounceFn(
    (value?: string) => getOrganizations(value),
    EDebounceTime.DEFAULT
)

const organizationsOptions = ref<OrganizationModel[]>([])
const selectedOrganization = computed(
    () => organizationsOptions.value.find(item => item.id === props.organizationId)?.name ?? ''
)
</script>

<template>
    <AutocompleteField
        :label="label ?? $t('user.create.existingOrganization')"
        :loading="loadingOrganization"
        :modelValue="selectedOrganization"
        :options="organizationsOptions"
        @typing="handleTyping"
        @select="$emit('select', $event as OrganizationModel)"
        :clearable="false" />
</template>
