<script setup lang="ts">
import checkpointApi from '@/services/checkpoint.service'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import { ECheckpointsPerm } from '@/types/permissions/CheckpointsPermissionsEnum'
import { userHasPermission } from '@/utils/permissionsUtils'
import { BaseCard, DeleteButton } from '@rfc/rfc-components-library'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const eventId = computed(() => parseInt(route.params.id.toString()))

type Props = {
    device: AssociatedDeviceModel
    checkpointId?: number
}
const props = defineProps<Props>()
const emit = defineEmits<{ (e: 'refresh'): void }>()

const dissociateDevice = () => {
    if (!props.checkpointId) return
    checkpointApi
        .unassociateDeviceFromCheckpoint(eventId.value, props.checkpointId, props.device.device_id)
        .then(res => {
            if (res.status === 204) emit('refresh')
        })
}

const hasEditCheckpointPerm = computed(() => userHasPermission(ECheckpointsPerm.CHECKPOINTS_EDIT))
const borderBottom = computed(() => (hasEditCheckpointPerm.value ? 'inherit' : 0))
</script>

<template>
    <BaseCard class="dissociate-device-card fit-content">
        <template #content>
            {{ device.name }}
        </template>
        <template v-if="hasEditCheckpointPerm" #footer>
            <DeleteButton
                :label="$t('checkpoint.detail.dissociateDevice')"
                class="full-width dissociate-button"
                @click="dissociateDevice" />
        </template>
    </BaseCard>
</template>

<style scoped lang="scss">
.dissociate-device-card {
    & :deep(.card-content) {
        text-align: center;
        border: 1px solid rgba(var(--v-theme-primary));
        border-bottom: v-bind(borderBottom);
    }
    & :deep(.card-footer) {
        border-top: unset;
    }
    & :deep(.button) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
.dissociate-button {
    &:deep(span) {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }
}
</style>
