<script setup lang="ts">
import DeviceTableCardMode from '@/components/DeviceComponents/Table/DeviceTableCardMode.vue'
import { DEVICE_ROUTES } from '@/router/routes'
import SocketDeviceAlertsWrapper from '@/socket/SocketDeviceAlertsWrapper.vue'
import { buildDetectionArray } from '@/socket/socketUtils'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { type DeviceModel } from '@/types/models/DeviceModel'
import { EOrganizationsDevicesPerm } from '@/types/permissions/OrganizationsPermissionsEnum'
import type { AlertSocketMessageModel } from '@/types/socket/SocketAlertModel'
import type { SocketDeviceDetectionModel } from '@/types/socket/SocketDetectionModel'
import { organizationService } from '@rfc/rfc-components-library/services'
import { EDetectionActions } from '@rfc/rfc-components-library/store'
import { onMounted, ref, watch } from 'vue'

type Props = {
    detections?: SocketDeviceDetectionModel[]
    eventId: number
    currentEvent?: boolean
    associates?: number[]
}
const props = defineProps<Props>()
type Emits = {
    (e: 'devicesUpdate', value: SocketDeviceDetectionModel[]): void
}
const emit = defineEmits<Emits>()

const latestDetections = ref<SocketDeviceDetectionModel[]>([])
watch(
    () => props.detections,
    newDetection => (newDetection?.length ? (latestDetections.value = newDetection) : undefined)
)
watch(
    () => props.associates,
    () => getDevices()
)

const devices = ref<DeviceModel[]>([])
const getDevices = () =>
    organizationService.getAllMyDevices({ filters: { event_id: props.eventId } }).then(res => {
        devices.value = res.data
        const devicesDetections = devices.value.map(item => ({
            device_id: item.device_id ?? item.id,
            detections: []
        }))
        store.value
            ?.dispatch(`${EStoreModules.DETECTION}/${EDetectionActions.GET_LATEST_DETECTIONS}`, {
                device_ids: devices.value.map(item => item.device_id)
            })
            .then((resDetections: DetectionModel[]) =>
                emit(
                    'devicesUpdate',
                    buildDetectionArray(resDetections, devicesDetections, 'device_id')
                )
            )
    })

onMounted(() => getDevices())

const deviceAlerts = ref<AlertSocketMessageModel[]>([])
const handleAlertsUpdate = (value: AlertSocketMessageModel[]) => (deviceAlerts.value = value)
</script>

<template>
    <SocketDeviceAlertsWrapper
        v-if="currentEvent"
        :device_ids="devices.map(item => item.device_id ?? item.id)"
        @update="handleAlertsUpdate" />

    <DeviceTableCardMode
        :data="devices"
        :statuses="deviceAlerts"
        :detections="detections"
        showNextCheckpoint
        :editPermission="EOrganizationsDevicesPerm.ORGANIZATIONS_DEVICES_VIEW"
        :editButtonHref="DEVICE_ROUTES.baseUrl" />
</template>
