<script setup lang="ts">
import EventCheckpointAutocomplete from '@/components/Filters/EventCheckpointAutocomplete.vue'
import { useGridSize } from '@/hooks/useGridSize'
import { ELocalStorage } from '@/types/enum/LocalStorageEnum'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { LocalStorageFilter } from '@/types/filters/DeviceFilters'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { AssociatedDeviceModel } from '@/types/models/DeviceModel'
import type { EventModel } from '@/types/models/EventModel'
import { getItemFromStorage } from '@/utils/localStorageUtils'
import { DeviceFilterAutocomplete, GridLayout, StringInputField } from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters: DetectionFilters
    deviceOptions?: AssociatedDeviceModel[]
    selectedEvent?: Partial<EventModel>
}
const props = defineProps<Props>()

type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
    (e: 'associates', value?: AssociatedDeviceModel[]): void
    (e: 'selectEvent', value?: EventModel): void
}
const emit = defineEmits<Emits>()

const gridSize = useGridSize()
const storedEvent = ref(getItemFromStorage<LocalStorageFilter>(ELocalStorage.FILTERS)?.event)

const handleChange = (value?: EventCheckpointType) => {
    if (!value) return
    storedEvent.value = undefined
    emit('change', {
        event_id: value.event?.id,
        checkpoint_id: value.checkpoint?.id,
        device_id: value.device_id
    })
}

const handleChangeEvent = (value?: EventCheckpointType) => {
    storedEvent.value = value?.event
    handleChange(value)
}

const handleTyping = (value: Record<string, string | number | undefined>) =>
    value ? debouncedOrganization(value) : emit('change', value)

const debouncedOrganization = useDebounceFn(
    (value: Record<string, string | number | undefined>) => emit('change', value),
    1000
)

const handleSelectEvent = (event?: EventModel) => emit('selectEvent', event)
</script>

<template>
    <GridLayout :maxItemPerRow="gridSize" :gap="8" class="full-width flex-wrap" isResponsive>
        <EventCheckpointAutocomplete
            :event="storedEvent"
            :checkpoint="undefined"
            keepEventInStorage
            filterAllEvents
            @change="handleChange($event)"
            @selectEvent="handleSelectEvent" />
        <DeviceFilterAutocomplete
            :filters="filters"
            :deviceOptions="selectedEvent?.detection_devices"
            @change="handleChangeEvent($event)" />
        <StringInputField
            :label="$t('detection.filters.bib')"
            :modelValue="filters.bib"
            :prefix="$t('unit.number')"
            @change="emit('change', { bib: $event })" />
    </GridLayout>
</template>
