<script setup lang="ts">
import { moment } from '@/locales/langUtils'
import { EStatus, EStatusLabels } from '@/types/enum/StatusEnum'
import type { ApiModel } from '@/types/models/ApiModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import {
    CreateButton,
    DatePickerField,
    InformationBaseCard,
    PermissionWrapper,
    ShowableTypography,
    StackLayout,
    StringInputField,
    ToggleButtonField,
    TypographyWithPrefix
} from '@rfc/rfc-components-library'
import { computed, ref } from 'vue'

type Props = {
    data: Partial<ApiModel>
    buttonLabel: string
    editPermission?: PermissionsEnums
    hasPermission?: boolean
    loading?: boolean
    isCreateMode?: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: Partial<ApiModel>): void
    (e: 'click'): void
}

const emit = defineEmits<Emits>()
const updateApiValue = (value: Partial<ApiModel>) =>
    emit('update', { ...(props.data ?? {}), ...value })

const data = computed(() => props.data)

const createApi = () => {
    submitted.value = true
    if (errors.value.nameError) return
    emit('click')
}

const submitted = ref(false)
const errors = computed(() => ({
    nameError: submitted.value && !props.data.name
}))

const apiStatus = computed({
    get: () => !!props.data.active,
    set: value =>
        emit('update', { ...props.data, active: value ? EStatus.ENABLED : EStatus.DISABLED })
})
const apiStatusLabel = computed(
    () => EStatusLabels[apiStatus.value ? EStatus.ENABLED : EStatus.DISABLED]
)
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <PermissionWrapper :permission="editPermission" :hasPermission="hasPermission">
            <CreateButton :loading="loading" :label="buttonLabel" @click="createApi" />
        </PermissionWrapper>
        <InformationBaseCard :label="$t('api.informationsTitle')">
            <StackLayout direction="column" :gap="16" class="full-width">
                <StackLayout direction="row" :gap="8" class="align-items-center">
                    <StringInputField
                        :label="$t('api.model.name')"
                        :modelValue="data.name"
                        @change="name => updateApiValue({ name })"
                        :placeholder="$t('api.model.name')"
                        required
                        :hasError="errors.nameError"
                        class="full-width" />
                    <DatePickerField
                        :label="$t('api.model.expirationDate')"
                        :modelValue="data.expiration_date"
                        @change="expiration_date => updateApiValue({ expiration_date })"
                        :placeholder="$t('api.model.expirationDate')"
                        :min-date="moment().startOf('d').toDate()"
                        clearable
                        class="full-width" />
                </StackLayout>

                <ToggleButtonField
                    :modelValue="apiStatus"
                    :label="$t(apiStatusLabel)"
                    @change="active => updateApiValue({ active: active ? 1 : 0 })" />
                <TypographyWithPrefix
                    v-if="!isCreateMode"
                    :prefix="$t('api.model.apiKey')"
                    class="full-width">
                    <template #edit>
                        <ShowableTypography :label="data.api_key" />
                    </template>
                </TypographyWithPrefix>
            </StackLayout>
        </InformationBaseCard>
    </StackLayout>
</template>
