<script setup lang="ts">
import { localFallback } from '@/locales/langUtils'
import type { ExtendedPrimitives } from '@/types/ExtendedPrimitives'
import type { ConfigType, PartnerConfigModel } from '@/types/models/PartnerConfigModel'
import type { PartnerModel } from '@/types/partners/PartnerModel'
import { EditableTypedInput, InformationBaseCard, StackLayout } from '@rfc/rfc-components-library'
import { computed } from 'vue'

type Props = {
    partnerConfig?: PartnerModel
    partnerConfigValues?: Record<string, ExtendedPrimitives>
    editMode: boolean
}
const props = defineProps<Props>()
type Emits = {
    (e: 'update', value: Partial<PartnerConfigModel>): void
}
defineEmits<Emits>()

const eventPartnerConfig = computed(() => props.partnerConfig?.config!.event)
const partnerWithoutConfig = computed(() => props.partnerConfigValues)
</script>

<template>
    <InformationBaseCard
        :label="`${$t('event.detail.informations.partnerConfigTitle')} : ${partnerConfig?.name}`"
        class="full-width fit-height">
        <StackLayout direction="column" :gap="8" class="full-width" v-if="eventPartnerConfig">
            <EditableTypedInput
                v-for="([itemKey, itemValue], key) in Object.entries(eventPartnerConfig)"
                :key="key"
                :value="partnerConfigValues?.[itemKey]"
                :label="
                    (itemValue as ConfigType)?.label
                        ? localFallback((itemValue as ConfigType)?.label) ?? itemKey
                        : itemKey
                "
                :dataType="(itemValue as ConfigType)?.type ?? itemValue"
                :editMode="editMode"
                @change="$emit('update', { [itemKey]: $event })" />
        </StackLayout>
        <StackLayout
            direction="column"
            :gap="8"
            class="full-width"
            v-else-if="partnerWithoutConfig">
            <EditableTypedInput
                v-for="([itemKey, itemValue], key) in Object.entries(partnerWithoutConfig)"
                :key="key"
                :value="itemValue"
                :label="itemKey"
                dataType="string"
                :editMode="editMode"
                @change="$emit('update', { [itemKey]: $event })" />
        </StackLayout>
    </InformationBaseCard>
</template>
