import type { AdministrationLocales } from '@/types/lang/locales'
import { administrationEn as libAdministrationEn } from '@rfc/rfc-components-library/locales'

export const administrationEn: AdministrationLocales = {
    ...libAdministrationEn,
    tabs: {
        admin: 'Administration',
        adminRfc: 'RFC Administration'
    },
    apis: {
        title: 'APIs',
        menuTitle: 'APIs list'
    },
    devices: {
        title: 'Equipments',
        menuTitle: 'Equipments list'
    },
    deviceConfigs: {
        title: 'Devices configuration'
    },
    organizations: {
        title: 'Organizations',
        menuTitle: 'Organizations list'
    },
    permissions: {
        title: 'Permissions',
        menuTitle: 'Permissions list'
    },
    roles: {
        title: 'Roles',
        menuTitle: 'Roles list'
    },
    users: {
        title: 'Users',
        menuTitle: 'Users list'
    },
    zones: {
        title: 'Zones',
        menuTitle: 'Zones list'
    },

    title: 'Administration',
    menuTitle: 'list'
}
