<script setup lang="ts">
import type { AssociatedDeviceModel, DeviceModel } from '@/types/models/DeviceModel'
import { EDebounceTime } from '@/utils/debounceUtils'
import { AutocompleteField, StackLayout, TypographyItem } from '@rfc/rfc-components-library'
import { organizationService } from '@rfc/rfc-components-library/services'
import type { PaginationDataType } from '@rfc/rfc-components-library/types'
import { useDebounceFn } from '@vueuse/core/index.cjs'
import { onMounted, ref, watch } from 'vue'

type Props = {
    associates: AssociatedDeviceModel[]
    editMode: boolean
}
const props = defineProps<Props>()
defineEmits<{
    (e: 'refresh'): void
    (e: 'select', device: DeviceModel): void
}>()

const device = ref()
watch(
    () => props.associates,
    () => (device.value = undefined)
)

const loadingDevices = ref(false)
const handleChange = (value: string) => {
    loadingDevices.value = true
    debouncedQuestion(value)
}
const debouncedQuestion = useDebounceFn((value: string) => getDevices(value), EDebounceTime.DEFAULT)
const getDevices = (name?: string) =>
    organizationService
        .getMyDevices(name ? { filters: { name } } : {})
        .then(
            res =>
                (devicesOptions.value =
                    (res.data as PaginationDataType<DeviceModel[]>).data?.filter(item =>
                        props.associates.find(associated => associated.id === item.id)
                            ? false
                            : item
                    ) ?? [])
        )
        .finally(() => (loadingDevices.value = false))

const devicesOptions = ref<DeviceModel[]>([])

onMounted(() => getDevices())
</script>

<template>
    <AutocompleteField
        v-if="editMode"
        :label="$t('device.filters.name')"
        v-model="device"
        :loading="loadingDevices"
        @typing="handleChange"
        @select="$emit('select', $event as DeviceModel)"
        :options="devicesOptions"
        class="width-fit">
        <template #item="props">
            <StackLayout direction="column">
                <TypographyItem :label="props.props.name" />
                <TypographyItem :label="`SN : ${props.props.serial_number}`" />
            </StackLayout>
        </template>
    </AutocompleteField>
</template>
