import { dark } from '@/scss/themes/dark'
import { light } from '@/scss/themes/light'
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'

export default createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            fa,
            mdi
        }
    },
    theme: {
        defaultTheme: 'dark',
        themes: {
            light,
            dark
        }
    }
})
