export const fr = {
    actions: 'Actions',
    add: 'Ajouter',
    advancedFilters: 'Filtres avancés',
    all: 'Tout',
    announcements: 'Annonces',
    archive: 'Archiver',
    back: 'Retour',
    cancel: 'Annuler',
    comment: 'Commentaire',
    confirm: {
        confirmAction: 'Confirmer',
        confirmContent: 'Souhaitez-vous confirmer {action} ? {complement}',
        confirmTitle: 'Confirmer {action} de "{item}"',

        add: "l'ajout",
        archive: "l'archivage",
        archiveComplement: 'Cette action est définitive',
        delete: 'la suppression',
        deleteComplement: 'Cette action est définitive',
        edit: "l'édition"
    },
    close: 'Fermer',
    connectedAs: 'Connecté en tant que',
    create: 'Créer',
    delete: 'Supprimer',
    description: 'Description',
    details: 'Détails',
    diagnostic: 'Diagnostic',
    edit: 'Editer',
    editMode: 'Passer en mode édition',
    exportToCsv: 'Exporter en CSV',
    hello: 'bonjour',
    modifiedBy: 'Modifié par',
    myAccount: 'Mon compte',
    next: 'Suivant',
    no: 'Non',
    noAnnouncement: 'Aucune annonce pour le moment',
    noHistory: "Aucun historique n'a été trouvé",
    noOption: 'Aucune option disponible',
    noRecord: 'Aucun résultat',
    noRole: 'Aucun rôle',
    object: 'Objet',
    or: 'ou',
    resolution: 'Résolution',
    save: 'Enregistrer',
    saveModifications: 'Enregistrer les modifications',
    scan: 'Scanner',
    search: 'Rechercher',
    send: 'Envoyer',
    showDetails: 'Voir détails',
    showMore: 'voir plus',
    sortBy: 'Trier par',
    theme: {
        light: 'Thème clair',
        dark: 'Thème sombre'
    },
    undefined: 'Non défini',
    unknown: 'Inconnu',
    upload: 'Ajouter des fichiers',
    validate: 'Valider',
    value: 'Valeur',
    typeYourSearch: 'Tapez votre recherche...',
    yes: 'Oui'
}

export const enumFr = {
    env: {
        production: 'PROD',
        development: 'DEV',
        preproduction: 'PREPROD',
        other: 'Autre'
    },
    keyCategory: {
        authentication: 'Authentification',
        configuration: 'Configuration',
        connectivity: 'Connectivité',
        informations: 'Informations',
        misc: 'Autres',
        rfid: 'RFID',
        specific: 'Spécifique',
        filter1: 'Filtre 1',
        filter2: 'Filtre 2'
    },
    qrcode: {
        error: {
            detectionError: "Aucun QR code n'a été détecté",
            notAllowed: "Vous devez autoriser l'accès à la caméra",
            notFound: "Il n'y a pas d'appareil photo sur ce périphérique",
            notSupported: 'Contexte sécurisé requis (HTTPS, localhost)',
            notReadable: 'La caméra est-elle déjà utilisée ?',
            overconstrained: 'Les caméras installées ne conviennent pas',
            streamApiNotSupported:
                "Cette fonctionnalité n'est pas prise en charge par ce navigateur",
            insecureContext:
                "L'accès à la caméra n'est autorisé que dans un contexte sécurisé. Utilisez HTTPS ou localhost plutôt que HTTP"
        },
        success: {
            detectionSuccess: 'Le code a bien été détecté'
        }
    },
    status: {
        disabled: 'Désactivé',
        enabled: 'Activé'
    },
    sortBy: {
        alphabeticalAsc: 'Ordre alphabétique',
        alphabeticalDesc: 'Ordre anti-alphabétique',
        dateRecent: 'Du plus récent',
        dateOld: 'Du plus ancien'
    },
    severity: {
        medium: 'Moyenne',
        high: 'Haute'
    },
    topic: {
        information: 'Information',
        dysfunction: 'Dysfonctionnement'
    },
    status_ticket: {
        technician: 'Technicien',
        commercial: 'Commercial'
    },
    validationMessages: {
        email: "L'entrée n'est pas un email."
    }
}
