<script setup lang="ts">
import { DEVICE_ROUTES, EVENT_CHECKPOINT_ROUTES } from '@/router/routes'
import type { DetectionModel } from '@/types/models/DetectionModel'
import { formatTimestamp } from '@/utils/dateUtils'
import { BaseTable, ClickableTypography, TypographyItem } from '@rfc/rfc-components-library'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()

type Props = {
    data?: DetectionModel[]
}
defineProps<Props>()

const route = useRoute()
const eventId = ref(parseInt(route.params.id.toString()))

const columns = [
    { value: 'bib', title: t('event.detection.table.bib') },
    { value: 'checkpoint_name', title: t('event.detection.table.checkpoint') },
    { value: 'device_name', title: t('event.detection.table.device') },
    { value: 'timestamp', title: t('event.detection.table.timestamp') },
    { value: 'event_filter', title: t('event.detection.table.event_filter') },
    { value: 'timer_filter', title: t('event.detection.table.timer_filter') }
]
</script>

<template>
    <BaseTable :columns="columns" :data="{ data }">
        <template #device_name="item">
            <ClickableTypography
                :label="item.device_name"
                :href="DEVICE_ROUTES.buildUrl(item.device_id)" />
        </template>
        <template #checkpoint_name="item">
            <ClickableTypography
                :label="item.checkpoint_name"
                :href="`${EVENT_CHECKPOINT_ROUTES.buildUrl(eventId)}/${item.checkpoint_id}`" />
        </template>
        <template #timestamp="item">
            <TypographyItem :label="formatTimestamp(item.timestamp)" />
        </template>
    </BaseTable>
</template>
