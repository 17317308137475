<script setup lang="ts">
import type { DeviceFiltersType } from '@/types/filters/DeviceFilters'
import { DeviceTypeSelector, StackLayout, StringInputField } from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters: DeviceFiltersType
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: DeviceFiltersType): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value?: string) => {
    loadingName.value = true
    debouncedName(value)
}
const loadingName = ref(false)
const debouncedName = useDebounceFn((name?: string) => emit('change', { name }), 500)
</script>

<template>
    <StackLayout :gap="8" isResponsive class="full-width">
        <StringInputField
            :label="$t('device.filters.nameOrSerial')"
            :modelValue="filters.name"
            @change="handleTyping" />
        <DeviceTypeSelector
            :modelValue="
                filters.device_type_id ? parseInt(filters.device_type_id.toString()) : undefined
            "
            @select="$emit('change', { device_type_id: $event?.id })" />
    </StackLayout>
</template>
