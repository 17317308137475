<script setup lang="ts">
import { LOGIN_URL } from '@/router'
import store from '@/store'
import { EAuthActions, EStoreModules } from '@rfc/rfc-components-library/store'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import PasswordView from './PasswordView.vue'

const route = useRoute()
const router = useRouter()

const queryParams = computed(() => ({
    token: route.params.token?.toString() ?? '',
    email: route.params.email?.toString() ?? ''
}))

const goToLogin = () => router.push(LOGIN_URL)
const submitCreation = (password?: string, password_confirmation?: string) => {
    if (!queryParams.value.email || !queryParams.value.token) return // TODO : Alerte
    store.value
        ?.dispatch(`${EStoreModules.AUTHENTICATION}/${EAuthActions.RESET_PASSWORD}`, {
            ...queryParams.value,
            password,
            password_confirmation
        })
        .then(res => {
            if (res.status === 200) router.push(LOGIN_URL)
        })
}
</script>

<template>
    <PasswordView
        :title="$t('authentication.createPassword.title')"
        :primaryLabel="$t('authentication.submit')"
        @clickSubmit="submitCreation"
        @clickSecondary="goToLogin">
        <template #title>
            <span class="bold">{{ queryParams.email }}</span>
        </template>
    </PasswordView>
</template>
