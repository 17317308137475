<script setup lang="ts">
import type { AddressModel } from '@/types/models/AddressModel'
import { AddressItem, InformationBaseCard, StackLayout } from '@rfc/rfc-components-library'
import { ref } from 'vue'

type Props = {
    address?: AddressModel
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<AddressModel>): void
}
defineEmits<Emits>()
const editMode = ref(false)
</script>

<template>
    <StackLayout direction="column" :gap="16">
        <InformationBaseCard
            class="full-width input-information-card"
            :label="$t('organization.informations.general')">
            <AddressItem
                :address="address"
                :editMode="editMode"
                @change="$emit('change', $event)" />
        </InformationBaseCard>
    </StackLayout>
</template>
