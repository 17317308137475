<script setup lang="ts">
import EventCheckpointAutocomplete from '@/components/Filters/EventCheckpointAutocomplete.vue'
import { useGridSize } from '@/hooks/useGridSize'
import type { DetectionFilters } from '@/types/filters/DetectionFilters'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { DetectionModel } from '@/types/models/DetectionModel'
import type { EventModel } from '@/types/models/EventModel'
import {
    DeviceFilterAutocomplete,
    GridLayout,
    NumberInputField,
    StringInputField
} from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'

type Props = {
    filters: DetectionFilters
    selectedEvent?: Partial<EventModel>
}
const props = defineProps<Props>()

type Emits = {
    (e: 'change', value: Partial<DetectionModel>): void
    (e: 'selectEvent', value?: EventModel): void
}
const emit = defineEmits<Emits>()

const gridSize = useGridSize()

const handleChange = (value?: EventCheckpointType) => {
    if (
        (props.filters.event_id ?? undefined) === (value?.event?.id ?? undefined) &&
        (props.filters.checkpoint_id ?? undefined) === (value?.checkpoint?.id ?? undefined) &&
        (props.filters.device_id ?? undefined) === (value?.device_id ?? undefined)
    )
        return
    emit('change', {
        event_id: value?.event?.id,
        checkpoint_id: value?.checkpoint?.id,
        device_id: value?.device_id
    })
}

const handleTyping = (value: Record<string, string | number | undefined>) =>
    value ? debouncedOrganization(value) : emit('change', value)

const debouncedOrganization = useDebounceFn(
    (value: Record<string, string | number | undefined>) => emit('change', value),
    1000
)

const onWheel = () => {}

const handleMinValue = (key: string, value: number | undefined, min: number) =>
    handleTyping({ [key]: (value ?? min) < min ? undefined : value })

const handleSelectEvent = (event?: EventModel) => emit('selectEvent', event)
</script>

<template>
    <GridLayout
        :maxItemPerRow="gridSize"
        :gap="8"
        :rowGap="12"
        class="full-width flex-wrap"
        isResponsive>
        <EventCheckpointAutocomplete
            :event="selectedEvent"
            :event_id="filters.event_id"
            :checkpoint_id="filters.checkpoint_id"
            keepEventInStorage
            filterAllEvents
            @change="handleChange($event)"
            @selectEvent="handleSelectEvent" />
        <DeviceFilterAutocomplete
            :filters="filters"
            :deviceOptions="selectedEvent?.detection_devices"
            @change="handleChange($event)" />
        <NumberInputField
            :label="$t('detection.filters.eventId')"
            :modelValue="filters.event_filter ?? undefined"
            @change="handleMinValue('event_filter', $event, 1)"
            :prefix="$t('unit.number')"
            :min="1"
            @wheel="onWheel" />
        <NumberInputField
            :label="$t('detection.filters.timerId')"
            :modelValue="filters.timer_filter ?? undefined"
            :prefix="$t('unit.number')"
            :min="1"
            @change="handleMinValue('timer_filter', $event, 1)"
            @wheel="onWheel" />

        <StringInputField
            :label="$t('detection.filters.bib')"
            :modelValue="filters.bib"
            @change="
                $event ? handleTyping({ bib: $event }) : $emit('change', { bib: undefined })
            " />
    </GridLayout>
</template>
