/* import { default as momentLib } from 'moment' */
import 'moment/locale/en-gb'
import 'moment/locale/fr'
// @ts-ignore
import { default as momentLib, type Moment } from 'moment/min/moment-with-locales'
import { computed } from 'vue'
import { i18n, type AvailableLangs, type AvailableLangsType } from './i18n'

export const LOCALE_KEY = 'i18nextLng'

export const getLang = () => localStorage.getItem(LOCALE_KEY) ?? 'en'

export const moment = computed<Moment>(() => {
    momentLib.locale(getLang())
    return momentLib
})

export const setLang = (locale: keyof AvailableLangsType) => {
    localStorage.setItem(LOCALE_KEY, locale)
    moment.value.locale(locale)
    i18n.global.locale.value = locale
}

export const localFallback = (
    langs: AvailableLangs<string>,
    undefinedString?: string,
    returnUndefinedString?: boolean
) =>
    langs[i18n.global.locale.value]
        ? langs[i18n.global.locale.value]
        : langs.en
        ? langs.en
        : returnUndefinedString
        ? i18n.global.t(undefinedString ?? 'common.undefined')
        : undefined
