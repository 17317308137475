<script setup lang="ts">
import type { TableEmits } from '@/types/components/CommonTableTypes'
import { ESortBy } from '@/types/enum/SortByEnum'
import type { RoleModel } from '@/types/models/RoleModel'
import type { PermissionsEnums } from '@/types/permissions/PermissionsEnum'
import { getQuerySort } from '@/utils/queryUtils'
import {
    FullTableLayout,
    HrefCreateButton,
    PermissionWrapper,
    StackLayout
} from '@rfc/rfc-components-library'
import type { PaginationDataType } from '@rfc/rfc-components-library/types'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

type Props = {
    data: PaginationDataType<RoleModel[]>
    loading?: boolean
    editPermission?: PermissionsEnums
    deletePermission?: PermissionsEnums
    createButtonHref?: string
    editButtonHref?: string
    forcePropsCurrentPage?: boolean
}
type Emits = TableEmits<RoleModel>

defineProps<Props>()
const emit = defineEmits<Emits>()

const { t } = useI18n()

const columns = [
    { value: 'id', title: t('role.table.id') },
    { value: 'name', title: t('role.table.name') }
]

const sort = ref(getQuerySort() ?? ESortBy.ALPHABETICAL_ASC)

const goToCreate = () => emit('goToCreate')
const goToDetail = (role: RoleModel) => emit('goToDetail', role)

const handleChangePageSize = (pageSize: number) => emit('changePageSize', pageSize)
const handleChangePageNumber = (pageNumber: number) => emit('changePageNumber', pageNumber)
</script>

<template>
    <StackLayout direction="column" :gap="12">
        <PermissionWrapper v-if="createButtonHref" :permission="editPermission">
            <HrefCreateButton
                :href="createButtonHref"
                :label="$t('role.buttons.createRole')"
                @click="goToCreate" />
        </PermissionWrapper>
        <FullTableLayout
            :sortBy="sort"
            :columns="columns"
            :data="data"
            :loading="data.loading"
            @changeSortBy="$emit('changeSortBy', $event)"
            :forcePropsCurrentPage="forcePropsCurrentPage"
            @change:pageSize="handleChangePageSize"
            @change:pageNumber="handleChangePageNumber">
            <template #filters>
                <slot name="filters" />
            </template>
        </FullTableLayout>
    </StackLayout>
</template>
