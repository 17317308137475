import { CRM_PREFIX } from '@/config/api'
import { ORGANIZATIONS_ROUTE } from '@/router/routing/organizationRouting/organizationRoutes'
import type { RentalModel } from '@/types/models/RentalModel'
import { crm } from '@rfc/rfc-components-library/helpers'

const BASE_ORGANIZATION_ROUTE = `${CRM_PREFIX}${ORGANIZATIONS_ROUTE}`

const ORGANIZATION_PREFIX = (organizationId: number) =>
    `${BASE_ORGANIZATION_ROUTE}/${organizationId}`

const organizationApi = {
    // RENTALS
    createRental: (organizationId: number, data: RentalModel) =>
        crm.post(`${ORGANIZATION_PREFIX(organizationId)}/rentals`, data)
}

export default organizationApi
