<script setup lang="ts">
import { BaseTable, TagStack, TypographyItem } from '@rfc/rfc-components-library'
import { type UserModel } from '@rfc/rfc-components-library/types'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type Props = {
    data?: UserModel[]
    titleItemKey?: string
    loading?: boolean
    editButtonHref?: string
}
defineProps<Props>()
type Emits = {
    (e: 'click', user: UserModel): void
    (e: 'delete', user: UserModel): void
}
const emit = defineEmits<Emits>()

const columns = [
    { value: 'id', title: t('organization.organizations.table.id') },
    { value: 'name', title: t('organization.organizations.table.name') },
    { value: 'email', title: t('organization.organizations.table.email') },
    { value: 'roles', title: t('organization.organizations.table.roles') }
]

const goTo = (user: UserModel) => emit('click', user)
const deleteRow = (user: UserModel) => emit('delete', user)

const table = ref<any>([])
</script>

<template>
    <BaseTable :columns="columns" :data="{ data: data ?? [] }" :loading="loading">
        <template #roles="item">
            <TagStack
                v-if="item.roles?.length"
                :data="item.roles?.map((item: string) => ({ name: item }))"
                :closable="false"
                class="tag-stack" />
            <TypographyItem v-else :label="$t('common.noRole')" />
        </template>
    </BaseTable>
</template>

<style scoped lang="scss">
.tag-stack {
    & :hover {
        background-color: rgba(var(--v-theme-primary));
    }
}
</style>
