<script setup lang="ts">
import { DEVICE_ROUTES } from '@/router/routes'
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { defaultCreateDevice } from '@/types/models/DeviceCreateModel'
import { type DeviceModel } from '@/types/models/DeviceModel'
import { DeviceCreatePage } from '@rfc/rfc-components-library'
import { EDeviceActions } from '@rfc/rfc-components-library/store'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const data = ref<Partial<DeviceModel>>({ ...defaultCreateDevice })

const updateDevice = (value: Partial<DeviceModel>) => (data.value = { ...data.value, ...value })

const create = () =>
    store.value
        ?.dispatch(`${EStoreModules.DEVICE}/${EDeviceActions.CREATE_DEVICE}`, data.value)
        .then(res => router.replace(DEVICE_ROUTES.buildUrl(res.id)))
</script>

<template>
    <DeviceCreatePage
        :deviceData="data"
        :loading="store?.state.device.loading"
        hasPermission
        @create="create"
        @change="updateDevice" />
</template>
