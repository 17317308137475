<script setup lang="ts">
import { mockupHomeAnnouncements } from '@/mockupData/HomeData'
import { ref } from 'vue'

const announcements = ref(mockupHomeAnnouncements)
</script>

<template>
    <div></div>
    <!-- <AnnouncementsLayout :announcements="announcements" /> -->
</template>

<style scoped lang="scss">
.announcements-layout {
    overflow-x: auto;
    padding-bottom: 0.5em;
}
</style>
