<script setup lang="ts">
import i18n from '@/locales/i18n'
import { FirefoxDragAndDrop } from '@rfc/rfc-components-library'
import { provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getCurrentThemeMode } from './utils/viewsUtils'

const router = useRouter()
const route = useRoute()
const store = useStore()
const { t } = useI18n()

provide('router', router)
provide('route', route)
provide('store', store)
provide('t', t)
provide('i18n', i18n)
</script>

<template>
    <FirefoxDragAndDrop />
    <div class="app-content">
        <v-responsive>
            <v-app :theme="getCurrentThemeMode">
                <RouterView />
            </v-app>
        </v-responsive>
    </div>
</template>
