import { store as libraryStore, organization } from '@rfc/rfc-components-library/store'
import { Store, createStore } from 'vuex'
import hubOrganization from './organizationStore/organization.module'
import { type UrtimeStoreState } from './storeType'

//import modules used
import { computed } from 'vue'
import type { Router } from 'vue-router'
import router from '../router'
import checkpoint from './checkpointStore/checkpoint.module'
import event from './eventStore/event.module'
import filters from './filtersStore/filters.module'
import notification from './notificationStore/notification.module'
import partner from './partnersStore/partners.module'

const setupStore = (storeRouter: Router): Store<UrtimeStoreState> =>
    createStore({
        modules: {
            ...libraryStore(storeRouter as any),
            checkpoint,
            event,
            filters,
            notification,
            partner,
            organization: {
                ...organization,
                actions: {
                    ...organization.actions(storeRouter),
                    ...hubOrganization.actions
                }
            }
        },
        devtools: true
    })

const store = computed(() => (router ? setupStore(router) : undefined))

export default store
