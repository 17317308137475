<script setup lang="ts">
import type { ApiFiltersType } from '@/types/filters/ApiFilters'
import { EDebounceTime } from '@/utils/debounceUtils'
import { DatePickerField, StackLayout, StringInputField } from '@rfc/rfc-components-library'
import { useDebounceFn } from '@vueuse/core'
import { ref } from 'vue'

type Props = {
    filters?: ApiFiltersType
}

defineProps<Props>()
type Emits = {
    (e: 'change', value: ApiFiltersType): void
}
const emit = defineEmits<Emits>()

const handleTyping = (value?: string) => {
    loadingName.value = true
    debouncedName(value)
}
const loadingName = ref(false)
const debouncedName = useDebounceFn(
    (name?: string) => emit('change', { name }),
    EDebounceTime.DEFAULT
)
</script>

<template>
    <StackLayout :gap="8" direction="column">
        <StackLayout :gap="8" class="responsive-width" isResponsiveSmallScreen>
            <StringInputField
                :label="$t('api.filters.name')"
                :modelValue="filters?.name"
                @change="handleTyping"
                class="responsive-width" />
            <StackLayout :gap="8" class="full-width">
                <DatePickerField
                    :label="$t('api.filters.expirationDateFrom')"
                    :modelValue="filters?.expirationDateFrom"
                    @change="$emit('change', { expirationDateFrom: $event })"
                    :min-date="new Date()"
                    class="full-width" />
                <DatePickerField
                    :label="$t('api.filters.expirationDateTo')"
                    :modelValue="filters?.expirationDateTo"
                    @change="$emit('change', { expirationDateTo: $event })"
                    :min-date="new Date()"
                    class="full-width" />
            </StackLayout>
        </StackLayout>
    </StackLayout>
</template>
