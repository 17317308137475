<script setup lang="ts">
import EventCheckpointAutocomplete from '@/components/Filters/EventCheckpointAutocomplete.vue'
import type { DeviceFiltersType } from '@/types/filters/DeviceFilters'
import type { EventCheckpointType } from '@/types/filters/EventCheckpointType'
import type { AdvancedEventFilters } from '@/types/filters/EventFilters'
import { StackLayout } from '@rfc/rfc-components-library'

type Props = {
    filters: DeviceFiltersType
}
defineProps<Props>()
type Emits = {
    (e: 'change', value: Partial<DeviceFiltersType>): void
}
const emit = defineEmits<Emits>()

const handleChange = (value: EventCheckpointType) => {
    if (value.checkpoint) return emit('change', { checkpoint_id: value.checkpoint.id })

    emit('change', { event_id: value.event?.id, checkpoint_id: undefined })
}

const eventFilters: AdvancedEventFilters = { upcoming: 1, ongoing: 1 }
</script>

<template>
    <StackLayout :gap="8" isResponsiveSmallScreen class="full-width">
        <EventCheckpointAutocomplete
            :eventFilters="eventFilters"
            keepEventInStorage
            @change="handleChange($event)" />
    </StackLayout>
</template>
