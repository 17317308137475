<script setup lang="ts">
import store from '@/store'
import { EStoreModules } from '@/store/storeType'
import { type PasswordType } from '@/types/PasswordType'
import {
    CreateButton,
    InformationBaseCard,
    StackLayout,
    StringInputField
} from '@rfc/rfc-components-library'
import { EUserActions } from '@rfc/rfc-components-library/store'
import type { UserModel } from '@rfc/rfc-components-library/types'
import { ref } from 'vue'

type Props = {
    me?: UserModel
}
defineProps<Props>()

const passwordData = ref<PasswordType>({
    current_password: undefined,
    new_password: undefined,
    new_password_confirmation: undefined
})

const save = () =>
    store.value?.dispatch(
        `${EStoreModules.USER}/${EUserActions.CHANGE_PASSWORD}`,
        passwordData.value
    )
</script>

<template>
    <InformationBaseCard :label="$t('account.changePasswordTitle')">
        <form class="full-width">
            <StackLayout direction="column" :gap="16" class="full-width">
                <StringInputField autocomplete="username" :style="{ display: 'none' }" />
                <!-- <PasswordField
                    :label="$t('account.model.currentPassword')"
                    :modelValue="passwordData.current_password"
                    @change="passwordData.current_password = $event"
                    autocomplete="current-password"
                    class="full-width" />
                <PasswordField
                    :label="$t('account.model.password')"
                    :modelValue="passwordData.new_password"
                    @change="passwordData.new_password = $event"
                    autocomplete="new-password"
                    class="full-width" />
                <PasswordField
                    :label="$t('account.model.confirmPassword')"
                    :modelValue="passwordData.new_password_confirmation"
                    @change="passwordData.new_password_confirmation = $event"
                    autocomplete="new-password"
                    class="full-width" /> -->
                <CreateButton
                    :loading="!!store?.state.user.loading"
                    :label="$t('common.save')"
                    @click="save" />
            </StackLayout>
        </form>
    </InformationBaseCard>
</template>

<style scoped lang="scss">
.profile-role-card {
    & .card-content {
        text-align: center;
        border: 1px solid rgba(var(--v-theme-primary));
    }
}
</style>
